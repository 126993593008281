import React, { Component } from 'react'
import {
  Layout,
  Form,
  Input,
  Button,
  Checkbox,
  Breadcrumb,
  Select,
  message,
  Spin,
} from 'antd'
import './BusinessRules.scss'
import { withRouter } from 'react-router-dom'
import { HTTPService } from '../../otherServices/apiService'
import { trimSpace, isPermission, isDisabled } from '../../otherServices/commonFunction'
import { PERMISSIONS_BY_NAME } from '../../otherServices/constant'
const { Content } = Layout

const { Option } = Select

class BusinessRules extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loader: false,
    }
  }
  componentDidMount() {
    this.fetchData()
  }

  //Function to call GET API for form data 
  async fetchData() {
    this.setState({ loader: true })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: 'businessrule/',
      })
      if (userRes.success) {
        if (userRes.data.results[0].itar_parts == 'Y') {
          userRes.data.results[0].itar_parts = true
        } else {
          userRes.data.results[0].itar_parts = false
        }

        if (userRes.data.results[0].disti_backorder_allowed == 'Y') {
          userRes.data.results[0].disti_backorder_allowed = true
        } else {
          userRes.data.results[0].disti_backorder_allowed = false
        }
        this.setState({ data: userRes.data.results, loader: false })
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (ex) {
      this.setState({ loader: false })
      message.error('Data not fetched')
    }
  }

  //Function to call submit form API
  handleFinish = async (value) => {
    this.setState({ loader: true })
    let id = this.state.data[0].id
    let data = trimSpace(value)

    if (data.itar_parts == 'Y' || data.itar_parts == true) {
      data.itar_parts = 'Y'
    } else {
      data.itar_parts = 'N'
    }

    if (
      data.disti_backorder_allowed == 'Y' ||
      data.disti_backorder_allowed == true
    ) {
      data.disti_backorder_allowed = 'Y'
    } else {
      data.disti_backorder_allowed = 'N'
    }
    try {
      let userRes = await HTTPService({
        method: 'PUT',
        url: `businessrule/${id}/`,
        data: {
          ...data,
        },
      })
      if (userRes.success) {
        this.setState({ loader: false })
        message.success("Form Submitted Successfully.")
        this.fetchData()
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (ex) {
      this.setState({ loader: false })
      message.error('Data not submited')
    }
  }

  onFinishFailed = (errors) => {
    
    message.error("Enter the required fields!")
  }

  renderForm(data) {
    let initialValues = data[0]
    const userRole = localStorage.getItem('role')
    return (
      <div className="BusinessRulesStyles">
        <Layout className="site-layout">
          <Content>
            <div className="layout_middel_content">
              <Breadcrumb>
                <Breadcrumb.Item>Business Rules To Bypass Digi-Key</Breadcrumb.Item>
              </Breadcrumb>
              <div className="BusinessRules_container">
                <div className="BusinessRules__title">
                  Business Rules to Bypass Digi-Key
                </div>
                <div className="layout_content_box BusinessRules_whitebox">
                  <Form
                    className="form_block_main business-rules-form"
                    initialValues={{ ...initialValues }}
                    onFinish={this.handleFinish}
                    onFinishFailed={this.onFinishFailed}
                  >
                    <div className="formitem_row">
                      <Form.Item label="Customer Name (Comma Separated)" name="customer_name">
                        <Input
                          placeholder="Bosch, apple, avent (Wild cards Comma Separated)"
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item label="Ship-to Name-Country (Comma Separated)" name="shipto_name">
                        <Input
                          placeholder="GALAXY-CN-HK, APPLE-US,IN (Wild cards Comma Separated)"
                          size="large"
                        />
                      </Form.Item>

                    </div>
                    <div className="formitem_row">
                      <Form.Item
                        label="Business Units (Comma Separated)"
                        name="business_units"
                      >
                        <Input placeholder="ABU" size="large" />
                      </Form.Item>
                      <Form.Item
                        label="Company of Origin (REl, IDT, ISL)"
                        name="company_of_origin"
                      >
                        <Input placeholder="REl" size="large" />
                      </Form.Item>

                    </div>
                    <div className="formitem_row">
                      <Form.Item
                        label="Division (Comma Separated)"
                        name="division"
                      >
                        <Input placeholder="Division" size="large" />
                      </Form.Item>
                      <Form.Item name="market_groups" label="Market Groups (Comma Separated)">
                        <Input placeholder="Market Groups" size="large" />
                      </Form.Item>

                    </div>

                    <div className="formitem_row">
                      <Form.Item
                        className="form_item_col_3"
                        label="ECCN for controlled parts (Comma Separated)"
                        name="eccn_for_controlled_parts"
                      >
                        <Input placeholder="ECCN for controlled parts" size="large" disabled={isDisabled(userRole)} />
                      </Form.Item>
                      <Form.Item
                        className="form_item_col_3"
                        label="Channel (Comma Separated)"
                        name="channel"
                      >
                        <Input placeholder="Channel" size="large" disabled={isDisabled(userRole)} />
                      </Form.Item>
                    </div>

                    <div className="formitem_row">
                      <Form.Item
                        label="Timer delay for international shipments (Days)"
                        name="international_shipment_time_delay"
                      >
                        <Input placeholder="2" size="large" disabled={isDisabled(userRole)} />
                      </Form.Item>
                      <Form.Item
                        className="form_item_col_3"
                        label="Inventory Buffer Multiplier"
                        name="inventory_buffer_multiplier"
                      >
                        <Input placeholder="1.35" size="large" disabled={isDisabled(userRole)} />
                      </Form.Item>

                    </div>

                    <div className="formitem_checkbox_row">
                      <Form.Item
                        name="itar_parts"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox>ITAR Parts</Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="disti_backorder_allowed"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox>
                          Backorder all REL Parts with distributor
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className="formitem_action_row">
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          size="large"
                          disabled={                                      //check if to disable save button
                            isPermission(
                              PERMISSIONS_BY_NAME['Manage Business Rules']
                            )
                              ? false
                              : true
                          }
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    )
  }

  render() {
    return this.state.loader ? (
      <div className="loader">
        <Spin />
      </div>
    ) : this.state.data ? (
      <div>{this.renderForm(this.state.data)}</div>
    ) : null
  }
}

export default withRouter(BusinessRules)
