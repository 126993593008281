import React from 'react'
import {
  Input,
  Button,
  Form,
  Select,
  DatePicker,
  TimePicker,
  message,
  Affix,
  Popover,
} from 'antd'
import moment from 'moment'
import './OrderTableEdit.scss'
import '../CustomModal/CustomModal.scss'
import { HTTPService } from '../../otherServices/apiService'
import { ArrowDownOutlined, InfoCircleOutlined } from '@ant-design/icons'
import {
  exportCSVFile,
  trimSpace,
  downloadExcel,
  isPermission,
  isDisabled,
  testAscii,
} from '../../otherServices/commonFunction'
import {
  DATE_FORMAT,
  TIME_FORMAT,
  PERMISSIONS_BY_NAME,
} from '../../otherServices/constant'
import TextArea from 'antd/lib/input/TextArea'
const { Option } = Select

let regEx
let isError1
let isError2
let isMDLNorderValidated
let iswebOrderValidated
let iswebOrderItemValidated
let isOrderStatusCanceled
// let isMDLNorderValidated
let isERPorderNumberValidated
let isERPorderItemValidated
let isChannelValidated
let isSalesOrgFilterValidated
let isOrderQtyValidated
let isApproveQtyValidated
let isOrderFulfillCompanyValidated
let isDistiErpAckQtyValidated
let isShippedQty
let isDistiErpBackorderValidated
let isShiptoCountryIsoValidated
let isContactLastNameValidated
let isUSCIvalidated
let isCompanyCountryIsoValidated
let isCompanyWebsiteValidated
let isDistiErpReasonValidated
let isROREmanRejectValidated
let isROREorderRejectUsernameValidated
let isNoneApproveReasonValidated
let isDistiShipSourceValidated
let isShipTypeValidated
let isCarrierNameValidated
let isWaybillValidated
let ispacklistValidated
let isOrderShipCompanyValidated
let isOppNumberValidated
let isEAUvalidated
let isProgramValidated
let isCoreInvQty
let isIntlShipmentValidated
let isGroupIdValidated
let isOrderRepocUsernameValidated
let isCommentsValidated
let isConsignInvQtyValidated
let isParentCompanyValidated
let isBusinessPurposeValidated
let isRequestorCompanyValidated
let isDPLByValidated
let isDPLAtValidated
let isDPLOnValidated
let isEUSHoldByValidated
let isEUSHoldAtValidated
let isEUSHoldOnValidated
let isS4OrderAckMdlnReceiveDateValidated
let isS4OrderAckMdlnReceiveTimeValidated
let isS4ShipConfMdlnReceiveDateValidated
let isS4ShipConfMdlnReceiveTimeValidated
let isManuallyCancelledValidated
let isCanSample
let isNewPartValidated
let isPartValidated
let isReqNameValidated
let isreqEmailValidated
let isreqTelNumValidated
let isContactFirstNameValidated
let iscontactEmailValidated
let isContactTelNoValidated
let iscompanyNameValidated
let isCompanyLocationValidated
let isCompanyAddress1Validated
let isCompanyAddress2Validated
let isCompanyCityValidated
let isCompanyStateValidated
let isCompanyStateISOValidated
let isCompanyZipValidated
let isCompanyCountryValidated
let isShiptoNameValidated
let isShiptoAddress1Validated
let isShiptoAddress2Validated
let isShiptoCityValidated
let isShiptoStateValidated
let isShiptoStateISOValidated
let isShiptoZipValidated
let isShiptoCountryValidated
let isTargetAppValidated
let isEndProductValidated
class OrderForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentOrder: props.currentOrder
        ? this.toMoment(props.currentOrder)
        : props.currentOrder,
      isUSAshipto: false,
      isUSAcountry: false,
      processLoader: false,
      isManuallyCancelled: false,
      isCancelValid: false,
      isChina: false,
      isSingaporeShiptoValidation: false,
      isSingaporeCompanyValidation: false,
      isHongKongShipTo: false,
      isHongKongComp: false,
      isMDLNflag: Math.random() * 100000,
    }
  }
  //format date and format on fetch
  toMoment = (currentOrder) => {
    if (currentOrder.rore_order_creat_date) {
      currentOrder.rore_order_creat_date = moment(
        currentOrder.rore_order_creat_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.rore_order_creat_time) {
      currentOrder.rore_order_creat_time = moment(
        currentOrder.rore_order_creat_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.sent_to_mdln_date) {
      currentOrder.sent_to_mdln_date = moment(
        currentOrder.sent_to_mdln_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.sent_to_mdln_time) {
      currentOrder.sent_to_mdln_time = moment(
        currentOrder.sent_to_mdln_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.mdln_approv_rej_date) {
      currentOrder.mdln_approv_rej_date = moment(
        currentOrder.mdln_approv_rej_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.mdln_approv_rej_time) {
      currentOrder.mdln_approv_rej_time = moment(
        currentOrder.mdln_approv_rej_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.ord_ack_to_cust_date) {
      currentOrder.ord_ack_to_cust_date = moment(
        currentOrder.ord_ack_to_cust_date
        // DATE_FORMAT
      )
    }

    if (currentOrder.ord_ack_to_cust_time) {
      currentOrder.ord_ack_to_cust_time = moment(
        currentOrder.ord_ack_to_cust_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.ord_cancel_to_cust_date) {
      currentOrder.ord_cancel_to_cust_date = moment(
        currentOrder.ord_cancel_to_cust_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.ord_cancel_to_cust_time) {
      currentOrder.ord_cancel_to_cust_time = moment(
        currentOrder.ord_cancel_to_cust_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.ord_rej_to_cust_date) {
      currentOrder.ord_rej_to_cust_date = moment(
        currentOrder.ord_rej_to_cust_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.ord_rej_to_cust_time) {
      currentOrder.ord_rej_to_cust_time = moment(
        currentOrder.ord_rej_to_cust_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.ord_conf_to_cust_date) {
      currentOrder.ord_conf_to_cust_date = moment(
        currentOrder.ord_conf_to_cust_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.ord_conf_to_cust_time) {
      currentOrder.ord_conf_to_cust_time = moment(
        currentOrder.ord_conf_to_cust_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.ship_ack_to_cust_date) {
      currentOrder.ship_ack_to_cust_date = moment(
        currentOrder.ship_ack_to_cust_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.ship_ack_to_cust_time) {
      currentOrder.ship_ack_to_cust_time = moment(
        currentOrder.ship_ack_to_cust_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.web_eoh_call_date) {
      currentOrder.web_eoh_call_date = moment(
        currentOrder.web_eoh_call_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.web_eoh_cal_time) {
      currentOrder.web_eoh_cal_time = moment(
        currentOrder.web_eoh_cal_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.lead_to_eloq_date) {
      currentOrder.lead_to_eloq_date = moment(
        currentOrder.lead_to_eloq_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.lead_to_eloq_time) {
      currentOrder.lead_to_eloq_time = moment(
        currentOrder.lead_to_eloq_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.order_fulfill_sent_date) {
      currentOrder.order_fulfill_sent_date = moment(
        currentOrder.order_fulfill_sent_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.order_fulfill_sent_time) {
      currentOrder.order_fulfill_sent_time = moment(
        currentOrder.order_fulfill_sent_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.order_reproc_date) {
      currentOrder.order_reproc_date = moment(
        currentOrder.order_reproc_date
        // DATE_FORMAT
      )
    }

    if (currentOrder.order_reproc_time) {
      currentOrder.order_reproc_time = moment(
        currentOrder.order_reproc_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.disti_erp_ack_rec_date) {
      currentOrder.disti_erp_ack_rec_date = moment(
        currentOrder.disti_erp_ack_rec_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.disti_erp_ack_rec_time) {
      currentOrder.disti_erp_ack_rec_time = moment(
        currentOrder.disti_erp_ack_rec_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.disti_erp_commit_date) {
      currentOrder.disti_erp_commit_date = moment(
        currentOrder.disti_erp_commit_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.disti_erp_error_rec_date) {
      currentOrder.disti_erp_error_rec_date = moment(
        currentOrder.disti_erp_error_rec_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.disti_erp_error_rec_time) {
      currentOrder.disti_erp_error_rec_time = moment(
        currentOrder.disti_erp_error_rec_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.disti_erp_backorder_rec_date) {
      currentOrder.disti_erp_backorder_rec_date = moment(
        currentOrder.disti_erp_backorder_rec_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.disti_erp_backorder_rec_time) {
      currentOrder.disti_erp_backorder_rec_time = moment(
        currentOrder.disti_erp_backorder_rec_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.ship_conf_date) {
      currentOrder.ship_conf_date = moment(
        currentOrder.ship_conf_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.ship_conf_time) {
      currentOrder.ship_conf_time = moment(
        currentOrder.ship_conf_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.ship_conf_mdln_sent_date) {
      currentOrder.ship_conf_mdln_sent_date = moment(
        currentOrder.ship_conf_mdln_sent_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.ship_conf_mdln_sent_time) {
      currentOrder.ship_conf_mdln_sent_time = moment(
        currentOrder.ship_conf_mdln_sent_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.rore_order_rej_date) {
      currentOrder.rore_order_rej_date = moment(
        currentOrder.rore_order_rej_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.rore_order_rej_time) {
      currentOrder.rore_order_rej_time = moment(
        currentOrder.rore_order_rej_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.consign_ship_conf_sent_date) {
      currentOrder.consign_ship_conf_sent_date = moment(
        currentOrder.consign_ship_conf_sent_date
        // DATE_FORMAT
      )
    }

    if (currentOrder.consign_ship_conf_sent_time) {
      currentOrder.consign_ship_conf_sent_time = moment(
        currentOrder.consign_ship_conf_sent_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.rore_order_modified_date) {
      currentOrder.rore_order_modified_date = moment(
        currentOrder.rore_order_modified_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.dpl_released_on) {
      currentOrder.dpl_released_on = moment(
        currentOrder.dpl_released_on
        // DATE_FORMAT
      )
    }
    if (currentOrder.eus_hold_released_on) {
      currentOrder.eus_hold_released_on = moment(
        currentOrder.eus_hold_released_on
        // DATE_FORMAT
      )
    }
    if (currentOrder.s4_order_ack_mdln_receive_date ) {
      currentOrder.s4_order_ack_mdln_receive_date  = moment(
        currentOrder.s4_order_ack_mdln_receive_date 
        // DATE_FORMAT
      )
    }
    if (currentOrder.s4_ship_conf_mdln_receive_date) {
      currentOrder.s4_ship_conf_mdln_receive_date = moment(
        currentOrder.s4_ship_conf_mdln_receive_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.rore_order_modified_time) {
      currentOrder.rore_order_modified_time = moment(
        currentOrder.rore_order_modified_time,
        TIME_FORMAT
      )
    }
    if (currentOrder.dup_date) {
      currentOrder.dup_date = moment(
        currentOrder.dup_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.dup_time) {
      currentOrder.dup_time = moment(currentOrder.dup_time, TIME_FORMAT)
    }
    if (currentOrder.intl_shipment_start_date) {
      currentOrder.intl_shipment_start_date = moment(
        currentOrder.intl_shipment_start_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.intl_shipment_start_time) {
      currentOrder.intl_shipment_start_time = moment(
        currentOrder.intl_shipment_start_time,
        TIME_FORMAT
      )
    }

    if (currentOrder.delay_timer_expiration_date) {
      currentOrder.delay_timer_expiration_date = moment(
        currentOrder.delay_timer_expiration_date
        // DATE_FORMAT
      )
    }
    if (currentOrder.manually_changed_time) {
      currentOrder.manually_changed_time = moment(
        currentOrder.manually_changed_time,
        TIME_FORMAT
      )
    }

    if (currentOrder.manually_changed_date) {
      currentOrder.manually_changed_date = moment(
        currentOrder.manually_changed_date
        // DATE_FORMAT
      )
    }
    return currentOrder
  }
  componentDidMount() {
    // isMDLNorderValidated = true
    const { currentOrder } = this.props
    const {
      isMDLNflag,
      isUSAshipto,
      isUSAcountry,
      isManuallyCancelled,
      isCancelValid,
      isSingaporeCompanyValidation,
      isSingaporeShiptoValidation,
      isChina,
      isHongKongShipTo,
      isHongKongComp,
    } = this.state

    if (
      !isPermission(
        PERMISSIONS_BY_NAME[('Add sample order', 'Manage Sample Order')]
      )
    ) {
      message.error('Only System Admin can edit!')
    }

    if (
      this.props &&
      this.props.currentOrder &&
      this.props.currentOrder.current_order_status.includes('Cancelled')
    ) {
      this.setState({ isCancelValid: true })
    }

    if (
      this.props &&
      this.props.currentOrder &&
      this.props.currentOrder.current_order_status == 'Manually Cancelled'
    ) {
      this.setState({ isManuallyCancelled: true })
    }
    if (
      this.props &&
      this.props.currentOrder &&
      this.props.currentOrder.shipto_country &&
      (this.props.currentOrder.shipto_country.toLowerCase() == 'usa' ||
        this.props.currentOrder.shipto_country.toLowerCase() == 'us')
    ) {
      isShiptoStateValidated =
        currentOrder &&
        (currentOrder.shipto_state.length > 35 ||
          currentOrder.shipto_state == '' ||
          currentOrder.shipto_state == null ||
          !testAscii(currentOrder.shipto_state))
      this.setState({ isUSAshipto: true })
    }
    if (
      this.props &&
      this.props.currentOrder &&
      this.props.currentOrder.shipto_country &&
      (this.props.currentOrder.shipto_country === 'Hong Kong' ||
        this.props.currentOrder.shipto_country === 'Hong Kong')
    ) {
      this.setState({ isHongKongShipTo: true })
    }
    if (
      this.props &&
      this.props.currentOrder &&
      this.props.currentOrder.company_country &&
      (this.props.currentOrder.company_country === 'Hong Kong' ||
        this.props.currentOrder.company_country === 'Hong Kong')
    ) {
      this.setState({ isHongKongComp: true })
    }
    if (
      this.props &&
      this.props.currentOrder &&
      this.props.currentOrder.company_country &&
      (this.props.currentOrder.company_country.toLowerCase() == 'usa' ||
        this.props.currentOrder.company_country.toLowerCase() == 'us')
    ) {
      isCompanyStateValidated =
        currentOrder &&
        (currentOrder.company_state.length > 35 ||
          currentOrder.company_state == '' ||
          currentOrder.company_state == null ||
          !testAscii(currentOrder.company_state))
      this.setState({ isUSAcountry: true })
    }
    if (
      this.props &&
      this.props.currentOrder &&
      this.props.currentOrder.shipto_country &&
      this.props.currentOrder.shipto_country.toLowerCase() === 'china'
    ) {
      this.setState({ isChina: true })
    }
    isShiptoCityValidated =
      currentOrder &&
      (currentOrder.shipto_city.length < 1 ||
        currentOrder.shipto_city.length > 40 ||
        !testAscii(currentOrder.shipto_city))
    if (
      this.props &&
      this.props.currentOrder &&
      this.props.currentOrder.shipto_country.toLowerCase() === 'singapore'
    ) {
      if (
        this.props &&
        this.props.currentOrder &&
        this.props.currentOrder.shipto_city.toLowerCase() !== 'singapore'
      ) {
        
        isShiptoCityValidated =
          currentOrder &&
          (true ||
            currentOrder.shipto_city.length > 40 ||
            !testAscii(currentOrder.shipto_city))
        this.setState({ isSingaporeShiptoValidation: true })
      } else {
        this.setState({ isSingaporeShiptoValidation: false })
      }
    }
    isCompanyCityValidated =
      currentOrder &&
      (currentOrder.company_city.length < 1 ||
        currentOrder.company_city.length > 40 ||
        !testAscii(currentOrder.company_city))
    if (
      this.props &&
      this.props.currentOrder &&
      this.props.currentOrder.company_country.toLowerCase() === 'singapore'
    ) {
      if (
        this.props &&
        this.props.currentOrder &&
        this.props.currentOrder.company_city.toLowerCase() !== 'singapore'
      ) {
        isCompanyCityValidated =
          currentOrder &&
          (true ||
            currentOrder.company_city.length > 40 ||
            !testAscii(currentOrder.company_city))
        this.setState({ isSingaporeCompanyValidation: true })
      } else {
        this.setState({ isSingaporeCompanyValidation: false })
      }
    }
   
    regEx = new RegExp(/^[0-9a-wA-Wy-zY-Z!@#$&()\-\`+,/\" ]*$/)
    isError1 = currentOrder && !regEx.test(currentOrder.contact_tel_number)
    isError2 = currentOrder && !regEx.test(currentOrder.req_tel_number)
    iswebOrderValidated =
      currentOrder &&
      (currentOrder.web_order > 5 || !testAscii(currentOrder.web_order))
    iswebOrderItemValidated =
      currentOrder && !testAscii(currentOrder.web_order_itm)
    isMDLNorderValidated = currentOrder && !testAscii(currentOrder.mdln_order)
    isOrderStatusCanceled =
      currentOrder &&
      currentOrder.current_order_status &&
      currentOrder.current_order_status.includes('Cancelled') &&
      currentOrder.reason_for_cancellation &&
      currentOrder.reason_for_cancellation.length < 1
    isERPorderNumberValidated =
      currentOrder && !testAscii(currentOrder.erp_order_number)
    isERPorderItemValidated =
    
      currentOrder && !testAscii(currentOrder.erp_order_item)
    isChannelValidated = currentOrder && ( currentOrder.cust_channel===null && currentOrder.channel)
    isSalesOrgFilterValidated =
      currentOrder && !testAscii(currentOrder.sales_org_filter)
    isOrderQtyValidated = currentOrder && !testAscii(currentOrder.order_qty)
    isApproveQtyValidated =
      currentOrder && !testAscii(currentOrder.approved_qty)
    isOrderFulfillCompanyValidated =
      currentOrder && !testAscii(currentOrder.order_fulfill_company)
    isDistiErpAckQtyValidated =
      currentOrder && !testAscii(currentOrder.disti_erp_ack_qty)
    isShippedQty = currentOrder && !testAscii(currentOrder.shipped_qty)
    isDistiErpBackorderValidated =
      currentOrder && !testAscii(currentOrder.disti_erp_backorder_qty)
    isShiptoCountryIsoValidated =
      currentOrder &&
      (currentOrder.shipto_country_ISO.length < 1 ||
        !testAscii(currentOrder.shipto_country_ISO))
    isContactLastNameValidated =
      currentOrder && !testAscii(currentOrder.contact_last_name)
    isUSCIvalidated = currentOrder && !testAscii(currentOrder.USCI)
    isCompanyCountryIsoValidated =
      currentOrder &&
      (currentOrder.company_country_ISO.length < 1 ||
        !testAscii(currentOrder.company_country_ISO))
    isCompanyWebsiteValidated =
      currentOrder && !testAscii(currentOrder.company_website)
    isDistiErpReasonValidated =
      currentOrder && !testAscii(currentOrder.disti_erp_error_reason)
    isROREmanRejectValidated =
      currentOrder && !testAscii(currentOrder.rore_man_reject)
    isROREorderRejectUsernameValidated =
      currentOrder && !testAscii(currentOrder.rore_order_rej_username)
    isNoneApproveReasonValidated =
      currentOrder && !testAscii(currentOrder.non_approv_reason)
    isDistiShipSourceValidated =
      currentOrder && !testAscii(currentOrder.disti_ship_source)
    isShipTypeValidated = currentOrder && !testAscii(currentOrder.ship_type)
    isCarrierNameValidated =
      currentOrder && !testAscii(currentOrder.carrier_name)
    isWaybillValidated = currentOrder && !testAscii(currentOrder.waybill)
    ispacklistValidated = currentOrder && !testAscii(currentOrder.packlist)
    isOrderShipCompanyValidated =
      currentOrder && !testAscii(currentOrder.order_ship_company)
    isOppNumberValidated = currentOrder && !testAscii(currentOrder.Opp_number)
    isEAUvalidated = currentOrder && !testAscii(currentOrder.eau)
    isProgramValidated = currentOrder && !testAscii(currentOrder.program)
    isCoreInvQty = currentOrder && !testAscii(currentOrder.core_inv_qty)
    isIntlShipmentValidated =
      currentOrder && !testAscii(currentOrder.intl_Shipment)
    isGroupIdValidated = currentOrder && !testAscii(currentOrder.grouping_ID)
    isOrderRepocUsernameValidated =
      currentOrder && !testAscii(currentOrder.order_reproc_username)
    isCommentsValidated = currentOrder && !testAscii(currentOrder.comments)
    isConsignInvQtyValidated =
      currentOrder && !testAscii(currentOrder.consign_inv_qty)
    isParentCompanyValidated =
      currentOrder && !testAscii(currentOrder.parent_company)
    isBusinessPurposeValidated =
      currentOrder && !testAscii(currentOrder.business_purpose)
    isRequestorCompanyValidated =
      currentOrder && !testAscii(currentOrder.requestor_company)
    isDPLAtValidated = currentOrder && !testAscii(currentOrder.dpl_released_at)
    isDPLByValidated = currentOrder && !testAscii(currentOrder.dpl_released_by)
    isDPLOnValidated = currentOrder && !testAscii(currentOrder.dpl_released_on)
    isEUSHoldAtValidated = currentOrder && !testAscii(currentOrder.eus_hold_released_at)
    isEUSHoldByValidated = currentOrder && !testAscii(currentOrder.eus_hold_released_by)
    isEUSHoldOnValidated = currentOrder && !testAscii(currentOrder.eus_hold_released_on)
    isS4OrderAckMdlnReceiveDateValidated = currentOrder && !testAscii(currentOrder.s4_order_ack_mdln_receive_date)
    isS4OrderAckMdlnReceiveTimeValidated = currentOrder && !testAscii(currentOrder.s4_order_ack_mdln_receive_time)
    isS4ShipConfMdlnReceiveDateValidated = currentOrder && !testAscii(currentOrder.s4_ship_conf_mdln_receive_date)
    isS4ShipConfMdlnReceiveTimeValidated = currentOrder && !testAscii(currentOrder.s4_ship_conf_mdln_receive_time)
    isManuallyCancelledValidated =
      currentOrder &&
      currentOrder.isManuallyCancelled &&
      (currentOrder.isManuallyCancelled.length < 1 ||
        !testAscii(currentOrder.isManuallyCancelled))
    isCanSample =
      currentOrder &&
      (currentOrder.can_sample === '0' || !testAscii(currentOrder.can_sample))
    isNewPartValidated =
      currentOrder &&
      (currentOrder.current_order_status === 'Part not sampleable' ||
        currentOrder.current_order_status === 'Part not available')
    isPartValidated =
      currentOrder &&
      (currentOrder.part_number.length > 40 ||
        !testAscii(currentOrder.part_number))
    isReqNameValidated =
      currentOrder &&
      (currentOrder.req_name.length > 40 || !testAscii(currentOrder.req_name))

    isreqEmailValidated =
      currentOrder &&
      (currentOrder.req_email.length > 50 || !testAscii(currentOrder.req_email))
    isreqTelNumValidated =
      currentOrder &&
      (currentOrder.req_tel_number.length < 6 ||
        currentOrder.req_tel_number.length > 15 ||
        isError2 ||
        !testAscii(currentOrder.req_tel_number))
    isContactFirstNameValidated =
      currentOrder &&
      (currentOrder.contact_first_name.length > 20 ||
        !testAscii(currentOrder.contact_first_name))
    iscontactEmailValidated =
      currentOrder &&
      (currentOrder.contact_email.length > 50 ||
        !testAscii(currentOrder.contact_email))
    isContactTelNoValidated =
      currentOrder &&
      (currentOrder.contact_tel_number.length < 6 ||
        currentOrder.contact_tel_number.length > 15 ||
        isError1 ||
        !testAscii(currentOrder.contact_tel_number))
    iscompanyNameValidated =
      currentOrder &&
      (currentOrder.company_name.length > 40 ||
        !testAscii(currentOrder.company_name))
    isCompanyLocationValidated =
      currentOrder &&
      (currentOrder.company_location.length > 40 ||
        !testAscii(currentOrder.company_location))

    isCompanyAddress1Validated =
      currentOrder &&
      currentOrder.company_addr1 &&
      (currentOrder.company_addr1.length > 40 ||
        !testAscii(currentOrder.company_addr1))
    isCompanyAddress2Validated =
      currentOrder &&
      currentOrder.company_addr2 &&
      (currentOrder.company_addr2.length > 40 ||
        !testAscii(currentOrder.company_addr2))
    // isCompanyCityValidated = currentOrder && (isSingaporeCompanyValidation || currentOrder.company_city.length > 40 || !testAscii(currentOrder.company_city))
    // isCompanyStateValidated = currentOrder && ((currentOrder.company_state.length > 35 || (isUSAcountry && (currentOrder.company_state == '' || currentOrder.company_state == null))) || !testAscii(currentOrder.company_state))
    isCompanyStateISOValidated =
      currentOrder &&
      currentOrder.company_state_ISO &&
      (currentOrder.company_state_ISO.length < 1 ||
        currentOrder.company_state_ISO.length > 5 ||
        !testAscii(currentOrder.company_state_ISO))
    isCompanyZipValidated =
      currentOrder &&
      currentOrder.company_zip &&
      (this.state.isHongKongComp
        ? currentOrder.company_zip.length > 10 ||
          !testAscii(currentOrder.company_zip)
        : currentOrder.company_zip.length < 1 ||
          currentOrder.company_zip.length > 10 ||
          !testAscii(currentOrder.company_zip))
    isCompanyCountryValidated =
      currentOrder &&
      currentOrder.company_country &&
      (isSingaporeCompanyValidation ||
        currentOrder.company_country.length < 1 ||
        currentOrder.company_country.length > 15 ||
        !testAscii(currentOrder.company_country))
    isShiptoNameValidated =
      currentOrder &&
      currentOrder.shipto_name &&
      (currentOrder.shipto_name.length > 40 ||
        !testAscii(currentOrder.shipto_name))
    isShiptoAddress1Validated =
      currentOrder &&
      currentOrder.shipto_addr1 &&
      (currentOrder.shipto_addr1.length > 40 ||
        !testAscii(currentOrder.shipto_addr1))
    isShiptoAddress2Validated =
      currentOrder &&
      currentOrder.shipto_addr2 &&
      (currentOrder.shipto_addr2.length > 40 ||
        !testAscii(currentOrder.shipto_addr2))
    // isShiptoCityValidated = currentOrder && (isSingaporeShiptoValidation || currentOrder.shipto_city.length > 40 || !testAscii(currentOrder.shipto_city))
    // isShiptoStateValidated = currentOrder && ((currentOrder.shipto_state.length > 35 || (isUSAshipto && (currentOrder.shipto_state == '' || currentOrder.shipto_state == null))) || !testAscii(currentOrder.shipto_state))
    isShiptoStateISOValidated =
      currentOrder &&
      (isUSAshipto
        ? (currentOrder.shipto_state_ISO &&
            currentOrder.shipto_state_ISO.length < 1) ||
          currentOrder.shipto_state_ISO.length > 5 ||
          !testAscii(currentOrder.shipto_state_ISO)
        : (currentOrder.shipto_state_ISO &&
            currentOrder.shipto_state_ISO.length > 5) ||
          !testAscii(currentOrder.shipto_state_ISO))
    isShiptoZipValidated =
      currentOrder &&
      (currentOrder.shipto_zip.length < 1 ||
        currentOrder.shipto_zip.length > 10 ||
        !testAscii(currentOrder.shipto_zip))
    isShiptoCountryValidated =
      currentOrder &&
      (isSingaporeShiptoValidation ||
        currentOrder.shipto_country.length < 1 ||
        currentOrder.shipto_country.length > 15 ||
        !testAscii(currentOrder.shipto_country))
    isTargetAppValidated =
      currentOrder &&
      currentOrder.target_app &&
      (currentOrder.target_app.length > 50 ||
        !testAscii(currentOrder.target_app))
    isEndProductValidated =
      currentOrder &&
      currentOrder.end_product &&
      (currentOrder.end_product.length > 50 ||
        !testAscii(currentOrder.end_product))
    this.forceUpdate()
  }

  //function to call API for form submit
  handleFinish = async (values) => {
    const { currentOrder } = this.state
    if (currentOrder) {
      values.id = currentOrder.id
    }
    this.setState({ processLoader: true })

    //format date and time before submit
    if (values.rore_order_creat_date) {
      values.rore_order_creat_date = moment(
        values.rore_order_creat_date
      ).format(DATE_FORMAT)
    }
    if (values.rore_order_creat_time) {
      values.rore_order_creat_time = moment(
        values.rore_order_creat_time
      ).format(TIME_FORMAT)
    }
    if (values.sent_to_mdln_date) {
      values.sent_to_mdln_date = moment(values.sent_to_mdln_date).format(
        DATE_FORMAT
      )
    }
    if (values.sent_to_mdln_time) {
      values.sent_to_mdln_time = moment(values.sent_to_mdln_time).format(
        TIME_FORMAT
      )
    }
    if (values.mdln_approv_rej_date) {
      values.mdln_approv_rej_date = moment(values.mdln_approv_rej_date).format(
        DATE_FORMAT
      )
    }
    if (values.mdln_approv_rej_time) {
      values.mdln_approv_rej_time = moment(values.mdln_approv_rej_time).format(
        TIME_FORMAT
      )
    }
    if (values.ord_ack_to_cust_date) {
      values.ord_ack_to_cust_date = moment(values.ord_ack_to_cust_date).format(
        DATE_FORMAT
      )
    }
    if (values.ord_ack_to_cust_time) {
      values.ord_ack_to_cust_time = moment(values.ord_ack_to_cust_time).format(
        TIME_FORMAT
      )
    }
    if (values.ord_cancel_to_cust_date) {
      values.ord_cancel_to_cust_date = moment(
        values.ord_cancel_to_cust_date
      ).format(DATE_FORMAT)
    }
    if (values.ord_cancel_to_cust_time) {
      values.ord_cancel_to_cust_time = moment(
        values.ord_cancel_to_cust_time
      ).format(TIME_FORMAT)
    }
    if (values.ord_rej_to_cust_date) {
      values.ord_rej_to_cust_date = moment(values.ord_rej_to_cust_date).format(
        DATE_FORMAT
      )
    }
    if (values.ord_rej_to_cust_time) {
      values.ord_rej_to_cust_time = moment(values.ord_rej_to_cust_time).format(
        TIME_FORMAT
      )
    }
    if (values.ord_conf_to_cust_date) {
      values.ord_conf_to_cust_date = moment(
        values.ord_conf_to_cust_date
      ).format(DATE_FORMAT)
    }
    if (values.ord_conf_to_cust_time) {
      values.ord_conf_to_cust_time = moment(
        values.ord_conf_to_cust_time
      ).format(TIME_FORMAT)
    }
    if (values.ship_ack_to_cust_date) {
      values.ship_ack_to_cust_date = moment(
        values.ship_ack_to_cust_date
      ).format(DATE_FORMAT)
    }
    if (values.ship_ack_to_cust_time) {
      values.ship_ack_to_cust_time = moment(
        values.ship_ack_to_cust_time
      ).format(TIME_FORMAT)
    }
    if (values.web_eoh_call_date) {
      values.web_eoh_call_date = moment(values.web_eoh_call_date).format(
        DATE_FORMAT
      )
    }
    if (values.web_eoh_cal_time) {
      values.web_eoh_cal_time = moment(values.web_eoh_cal_time).format(
        TIME_FORMAT
      )
    }
    if (values.lead_to_eloq_date) {
      values.lead_to_eloq_date = moment(values.lead_to_eloq_date).format(
        DATE_FORMAT
      )
    }
    if (values.lead_to_eloq_time) {
      values.lead_to_eloq_time = moment(values.lead_to_eloq_time).format(
        TIME_FORMAT
      )
    }
    if (values.order_fulfill_sent_date) {
      values.order_fulfill_sent_date = moment(
        values.order_fulfill_sent_date
      ).format(DATE_FORMAT)
    }
    if (values.order_fulfill_sent_time) {
      values.order_fulfill_sent_time = moment(
        values.order_fulfill_sent_time
      ).format(TIME_FORMAT)
    }
    if (values.order_reproc_date) {
      values.order_reproc_date = moment(values.order_reproc_date).format(
        DATE_FORMAT
      )
    }
    if (values.order_reproc_time) {
      values.order_reproc_time = moment(values.order_reproc_time).format(
        TIME_FORMAT
      )
    }
    if (values.disti_erp_ack_rec_date) {
      values.disti_erp_ack_rec_date = moment(
        values.disti_erp_ack_rec_date
      ).format(DATE_FORMAT)
    }
    if (values.disti_erp_ack_rec_time) {
      values.disti_erp_ack_rec_time = moment(
        values.disti_erp_ack_rec_time
      ).format(TIME_FORMAT)
    }
    if (values.disti_erp_commit_date) {
      values.disti_erp_commit_date = moment(
        values.disti_erp_commit_date
      ).format(DATE_FORMAT)
    }
    if (values.disti_erp_error_rec_date) {
      values.disti_erp_error_rec_date = moment(
        values.disti_erp_error_rec_date
      ).format(DATE_FORMAT)
    }
    if (values.disti_erp_error_rec_time) {
      values.disti_erp_error_rec_time = moment(
        values.disti_erp_error_rec_time
      ).format(TIME_FORMAT)
    }
    if (values.disti_erp_backorder_rec_date) {
      values.disti_erp_backorder_rec_date = moment(
        values.disti_erp_backorder_rec_date
      ).format(DATE_FORMAT)
    }
    if (values.disti_erp_backorder_rec_time) {
      values.disti_erp_backorder_rec_time = moment(
        values.disti_erp_backorder_rec_time
      ).format(TIME_FORMAT)
    }
    if (values.ship_conf_date) {
      values.ship_conf_date = moment(values.ship_conf_date).format(DATE_FORMAT)
    }
    if (values.ship_conf_time) {
      values.ship_conf_time = moment(values.ship_conf_time).format(TIME_FORMAT)
    }
    if (values.ship_conf_mdln_sent_date) {
      values.ship_conf_mdln_sent_date = moment(
        values.ship_conf_mdln_sent_date
      ).format(DATE_FORMAT)
    }
    if (values.ship_conf_mdln_sent_time) {
      values.ship_conf_mdln_sent_time = moment(
        values.ship_conf_mdln_sent_time
      ).format(TIME_FORMAT)
    }
    if (values.rore_order_rej_date) {
      values.rore_order_rej_date = moment(values.rore_order_rej_date).format(
        DATE_FORMAT
      )
    }
    if (values.rore_order_rej_time) {
      values.rore_order_rej_time = moment(values.rore_order_rej_time).format(
        TIME_FORMAT
      )
    }
    if (values.consign_ship_conf_sent_date) {
      values.consign_ship_conf_sent_date = moment(
        values.consign_ship_conf_sent_date
      ).format(DATE_FORMAT)
    }
    if (values.consign_ship_conf_sent_time) {
      values.consign_ship_conf_sent_time = moment(
        values.consign_ship_conf_sent_time
      ).format(TIME_FORMAT)
    }
    if (values.rore_order_modified_date) {
      values.rore_order_modified_date = moment(
        values.rore_order_modified_date
      ).format(DATE_FORMAT)
    }
    if (values.dpl_released_on) {
      values.dpl_released_on = moment(values.dpl_released_on).format(
        DATE_FORMAT
      )
    }
    if (values.eus_hold_released_on) {
      values.eus_hold_released_on = moment(values.eus_hold_released_on).format(
        DATE_FORMAT
      )
    }
    if (values.s4_order_ack_mdln_receive_date) {
      values.s4_order_ack_mdln_receive_date = moment(values.s4_order_ack_mdln_receive_date).format(
        DATE_FORMAT
      )
    }
    if (values.s4_ship_conf_mdln_receive_date) {
      values.s4_ship_conf_mdln_receive_date = moment(values.s4_ship_conf_mdln_receive_date).format(
        DATE_FORMAT
      )
    }
    if (values.rore_order_modified_time) {
      values.rore_order_modified_time = moment(
        values.rore_order_modified_time
      ).format(TIME_FORMAT)
    }
    if (values.dup_date) {
      values.dup_date = moment(values.dup_date).format(DATE_FORMAT)
    }
    if (values.dup_time) {
      values.dup_time = moment(values.dup_time).format(TIME_FORMAT)
    }
    if (values.intl_shipment_start_date) {
      values.intl_shipment_start_date = moment(
        values.intl_shipment_start_date
      ).format(DATE_FORMAT)
    }
    if (values.intl_shipment_start_time) {
      values.intl_shipment_start_time = moment(
        values.intl_shipment_start_time
      ).format(TIME_FORMAT)
    }
    if (values.delay_timer_expiration_date) {
      values.delay_timer_expiration_date = moment(
        values.delay_timer_expiration_date
      ).format(DATE_FORMAT)
    }
    if (values.manually_changed_time) {
      values.manually_changed_time = moment(
        values.manually_changed_time
      ).format(TIME_FORMAT)
    }
    if (values.manually_changed_date) {
      values.manually_changed_date = moment(
        values.manually_changed_date
      ).format(DATE_FORMAT)
    }
    let valuesData = trimSpace(values)
    try {
      let userRes
      if (values.id) {
        userRes = await HTTPService({
          method: 'PUT',
          url: `sampleorder/${values.id}/`,
          data: {
            ...values,
          },
        })
      } else {
        userRes = await HTTPService({
          method: 'POST',
          url: 'sampleorder/',
          data: {
            ...valuesData,
          },
        })
      }
      if (userRes.success) {
        message.success(`Order ${values.id ? 'Updated' : 'Added'} `)
        this.props.onChangePageNumber(1, this.props.pageLimit)
      } else {
        message.error(userRes.message)
        this.setState({
          currentOrder: values,
          processLoader: false,
        })
      }
    } catch (error) {
      this.setState({
        currentOrder: values,
        processLoader: false,
      })
      message.error(`Failed to ${values.id ? 'Updated' : 'Added'}`)
    }
  }

  handleShiptoCountryChange = (e) => {
    let shiptoCountry = e.target.value.toLowerCase()
    if (shiptoCountry === 'usa' || shiptoCountry === 'us') {
      this.setState({ isUSAshipto: true })
    }
    if (shiptoCountry == 'china') {
      this.setState({ isChina: true })
    }
    if (shiptoCountry === 'singapore') {
      let shiptoCity =
        this.state.currentOrder && this.state.currentOrder.shipto_city
      if (shiptoCountry !== shiptoCity.toLowerCase()) {
        isShiptoCityValidated =
          this.state.currentOrder &&
          (this.state.currentOrder.shipto_city.length > 40 ||
            !testAscii(this.state.currentOrder.shipto_city))
        this.setState({ ...this.state, isSingaporeShiptoValidation: true })
      }
    } else {
      if (isShiptoCountryValidated) {
        isShiptoCountryValidated = false
        this.setState({
          ...this.state,
          isMDLNflag: Math.random() * 10000,
        })
      }
    }
  }

  handleComapanyCountry = (e) => {
    let countryCompany = e.target.value.toLowerCase()
    if (countryCompany === 'singapore') {
      let companyCity =
        this.state.currentOrder && this.state.currentOrder.company_city
      if (countryCompany !== companyCity.toLowerCase()) {
        isCompanyStateValidated =
          this.state.currentOrder &&
          (this.state.currentOrder.company_state.length > 35 ||
            this.state.currentOrder.company_state == '' ||
            this.state.currentOrder.company_state == null ||
            !testAscii(this.state.currentOrder.company_state))
        this.setState({ ...this.state, isSingaporeCompanyValidation: true })
      }
    }
    if (countryCompany === 'usa' || countryCompany === 'us') {
      this.setState({ isUSAcountry: true })
    } else {
      if (isCompanyCountryValidated) {
        isCompanyCountryValidated = false
        this.setState({
          ...this.state,
          isMDLNflag: Math.random() * 10000,
        })
      }
    }
  }

  handleShiptoCityChange = (e) => {
    let shiptoCity = e.target.value.toLowerCase()
    let shiptoCountry =
      this.state.currentOrder && this.state.currentOrder.shipto_country

    if (shiptoCountry === 'singapore') {
      if (shiptoCity !== shiptoCountry.toLowerCase()) {
        isShiptoCityValidated =
          this.state.currentOrder &&
          (this.state.currentOrder.shipto_city.length > 40 ||
            !testAscii(this.state.currentOrder.shipto_city))
        this.setState({ ...this.state, isSingaporeShiptoValidation: true })
      }
    } else {
      if (isShiptoCityValidated) {
        isShiptoCityValidated = false
        this.setState({
          ...this.state,
          isMDLNflag: Math.random() * 10000,
        })
      }
    }
  }

  handleComapanyCity = (e) => {
    let countryCity = e.target.value.toLowerCase()
    let companyCountry =
      this.state.currentOrder && this.state.currentOrder.company_country
    if (companyCountry === 'singapore') {
      if (countryCity !== companyCountry.toLowerCase()) {
        isCompanyStateValidated =
          this.state.currentOrder &&
          (this.state.currentOrder.company_state.length > 35 ||
            this.state.currentOrder.company_state == '' ||
            this.state.currentOrder.company_state == null ||
            !testAscii(this.state.currentOrder.company_state))
        this.setState({ ...this.state, isSingaporeCompanyValidation: true })
      }
    } else {
      if (isCompanyCityValidated) {
        isCompanyCityValidated = false
        this.setState({
          ...this.state,
          isMDLNflag: Math.random() * 10000,
        })
      }
    }
  }

  //function to handle form submit failure
  onFinishFailed = (errors, values) => {
    
    message.error('Enter the required fields!')
  }

  handleSelect = (e) => {
    if (e == 'Manually Cancelled') {
      this.setState({ isManuallyCancelled: true })
    } else {
      this.setState({ isManuallyCancelled: false })
    }
  }

  render() {
    const {
      currentOrder,
      isMDLNflag,
      isUSAshipto,
      isUSAcountry,
      isCancelValid,
      isManuallyCancelled,
      isSingaporeCompanyValidation,
      isSingaporeShiptoValidation,
      isChina,
    } = this.state
    const userRole = localStorage.getItem('role')
    
    const { isAddPart, isUser } = this.props
    const validationMessage = 'Maximum field length is '
    const regEx = new RegExp(/^[0-9a-wA-Wy-zY-Z!@#$&()\-\`+,/\" ]*$/)
    
    const isErroratDigiKey =
      currentOrder && currentOrder.current_order_status == 'Error in DK'
    const isStatusBlank =
      (currentOrder && currentOrder.current_order_status) == '' ? true : false
    const isSentforApproval =
      (currentOrder && currentOrder.current_order_status) == 'Sent for Approval'
        ? true
        : false

    const isErrorinDigiKey =
      currentOrder &&
      +(
        currentOrder.current_order_status == 'Error in IDT' ||
        currentOrder.current_order_status == 'Error in ISL' ||
        currentOrder.current_order_status == 'Error in Digikey'
      )
      const isErrorinDk =
      (currentOrder && currentOrder.current_order_status) == 'Error in DK'
        ? true
        : false
      const isErrorinIDT =
      (currentOrder && currentOrder.current_order_status) == 'Error in IDT' ||(currentOrder && currentOrder.current_order_status) == 'Error in ISL'
      ? true
      : false
    const isPendingApproval =
      (currentOrder && currentOrder.current_order_status) ==
      'Pending for Approval'
        ? true
        : false
    const isrestrictedStatus =
      currentOrder &&
      (currentOrder.current_order_status == 'Sent to Digikey' ||
        currentOrder.current_order_status == 'Sent to IDT SAP' ||
        currentOrder.current_order_status == 'Sent to ISL Oracle' ||
        currentOrder.current_order_status == 'Sent to REL SAP' ||
        currentOrder.current_order_status == 'Order ACK received' ||
        currentOrder.current_order_status == 'Backordered at Digikey' ||
        currentOrder.current_order_status == 'Error in DK' || 
        currentOrder.current_order_status == 'Error in IDT'||
        currentOrder.current_order_status == 'Error in ISL'
        )
        
        ? true
        : false
    // const isErroratDigiKey = currentOrder && (currentOrder.current_order_status == 'Error in DK')
    const isShippedFrom =
      currentOrder &&
      (currentOrder.current_order_status == 'Shipped from IDT' ||
        currentOrder.current_order_status == 'Shipped from ISL' ||
        currentOrder.current_order_status == 'Shipped from DK' ||
        currentOrder.current_order_status == 'Shipped from DigiKey' ||
        currentOrder.current_order_status == 'Shipped from REL' ||
        currentOrder.current_order_status == 'Order on DPL Hold')
        ? true
        : false

    const isEUSholdApproval =
        (currentOrder && currentOrder.current_order_status) == 'EUS Hold'
          ? true
          : false
    
    const isEUShold =
          (currentOrder && currentOrder.current_order_status) == 'EUS Hold'
            ? true
            : false

    const content = (
      <div>
        {/* <p>{`Special characters(!@$%^&*()_+<>) not allowed.`}</p> */}
        <p> Tel Number should not start with x or dot</p>
      </div>
    )

   
    return (
      <div className="AddOrderStyles">
        <div className="AddOrder_container">
          <div className="AddOrder__title">
            {currentOrder ? 'Edit ' : 'Add '} Order
          </div>
          <div className="layout_content_box AddOrder_whitebox">
            <Form
              aacceptcharset="utf-8"
              className="form_block_main AddOrder-form"
              initialValues={{ ...currentOrder }}
              onFinish={this.handleFinish}
              onFinishFailed={this.onFinishFailed}
              scrollToFirstError
            >
              <div className="formitem_row">
                {currentOrder && (
                  <Form.Item
                    label="Rore Order"
                    name="rore_order"
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              max: 20,
                              message: `${validationMessage}20`,
                            },
                          ]
                    }
                    className={
                      isManuallyCancelled
                        ? ''
                        : iswebOrderValidated
                        ? 'req_error'
                        : ''
                    }
                  >
                    <Input
                      placeholder="Enter Rore Order"
                      size="large"
                      disabled={currentOrder}
                    />
                  </Form.Item>
                )}
              </div>
              <div className="formitem_row">
                {!currentOrder && (
                  <Form.Item
                    label="Rore Order"
                    name="rore_order"
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              max: 20,
                              message: `${validationMessage}20`,
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Rore Order"
                      size="large"
                      disabled={currentOrder}
                    />
                  </Form.Item>
                )}

                {currentOrder && isrestrictedStatus && isDisabled(userRole) && (
                  <Form.Item name="current_order_status" label="Order Status">
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder="Order Status"
                      style={{ width: '100%' }}
                      name="current_order_status"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => this.handleSelect(e)}
                    >
                      
                  {isErrorinDk  ?  <Option value={"Cancelled by Digikey"}>Cancelled by Digikey</Option> :  <Option value="Cancelled in ERP">Cancelled in ERP</Option>}


                      
                  
                  </Select>
                  </Form.Item>
                )}

                {currentOrder && isShippedFrom && isDisabled(userRole) && (
                  <Form.Item name="current_order_status" label="Order Status">
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder="Order Status"
                      style={{ width: '100%' }}
                      name="current_order_status"
                      disabled={isUser ? !isDisabled(userRole) && !isAddPart : false}
                      onChange={(e) => this.handleSelect(e)}
                    >
                      <Option value="Cancelled in ERP">Cancelled in ERP</Option>
                      {/* <Option value="Manually Cancelled">
                          Manually Cancelled
                        </Option> */}
                    </Select>
                  </Form.Item>
                )}

                {/* {currentOrder && isErrorinDigiKey && isDisabled(userRole) && (
                  <Form.Item name="current_order_status" label="Order Status">
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder="Order Status"
                      style={{ width: '100%' }}
                      name="current_order_status"
                      onChange={(e) => this.handleSelect(e)}
                    >
                      <Option value=""></Option>
                    </Select>
                  </Form.Item>
                )} */}
                {/* {currentOrder &&
                  isErrorinDk && isDisabled(userRole) &&
                 (
                    <Form.Item name="current_order_status" label="Order Status">
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder="Order Status"
                        style={{ width: '100%' }}
                        name="current_order_status"
                        disabled={
                          isUser ? !isDisabled(userRole) && !isAddPart : false
                        }
                        onChange={(e) => this.handleSelect(e)}
                      >
                        <Option value="Cancelled by Digikey">Cancelled by Digikey</Option>
                      
                      </Select>
                    </Form.Item>
                  )}
                  {currentOrder &&
                    isErrorinIDT && isDisabled(userRole) &&
                 (
                    <Form.Item name="current_order_status" label="Order Status">
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder="Order Status"
                        style={{ width: '100%' }}
                        name="current_order_status"
                        disabled={
                          isUser ? !isDisabled(userRole) && !isAddPart : false
                        }
                        onChange={(e) => this.handleSelect(e)}
                      >
                        <Option value="Cancelled in ERP">Cancelled in ERP</Option>
                      
                      </Select>
                    </Form.Item>
                  )} */}
                 {/* {currentOrder &&
                  (isSentforApproval) &&
                  isDisabled(userRole) && (
                    <Form.Item name="current_order_status" label="Order Status">
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder="Order Status"
                        style={{ width: '100%' }}
                        name="current_order_status"
                        disabled={
                          isUser ? !isDisabled(userRole) && !isAddPart : false
                        }
                        onChange={(e) => this.handleSelect(e)}
                      >
                        <Option value="Manually Cancelled">
                          Manually Cancelled
                        </Option>
                        <Option value="">
                        </Option>
                      </Select>
                    </Form.Item>
                  )}  */}
                {currentOrder && !isDisabled(userRole) && (
                  <Form.Item name="current_order_status" label="Order Status">
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder="Order Status"
                      style={{ width: '100%' }}
                      name="current_order_status"
                      disabled={
                        isUser && !isShippedFrom
                          ? !isDisabled(userRole) && !isAddPart
                          : false
                      }
                      onChange={(e) => this.handleSelect(e)}
                      // || currentOrder.current_order_status == 'Shipped from xxx')
                    >
                      <Option value="Order Created">Order Created</Option>

                      <Option value="Sent for Approval">
                        Sent for Approval
                      </Option>

                      <Option value="Pending for Approval">
                        Pending for Approval
                      </Option>

                      <Option value="Approved">Approved</Option>

                      <Option value="Not Approved">Not Approved</Option>

                      <Option value="Sent to Digikey">Sent to Digikey</Option>

                      <Option value="Sent to IDT SAP">Sent to IDT SAP</Option>

                      <Option value="Sent to ISL Oracle">
                        Sent to ISL Oracle
                      </Option>

                      <Option value="Sent to REL SAP">Sent to REL SAP</Option>

                      <Option value="Order ACK received">
                        Order ACK received
                      </Option>

                      <Option value="Backordered at Digikey">
                        Backordered at Digikey
                      </Option>

                      <Option value="Error in DK">Error in DK</Option>
                      <Option value="Shipped from Digikey">
                        Shipped from Digikey
                      </Option>

                      <Option value="Shipped from IDT">Shipped from IDT</Option>

                      <Option value="Shipped from ISL">Shipped from ISL</Option>

                      <Option value="Shipped from REL">Shipped from REL</Option>

                      {isErrorinDk &&  <Option value={"Cancelled by Digikey"}>Cancelled by Digikey</Option>}
                      
                      <Option value="Manually Cancelled">
                        Manually Cancelled
                      </Option>
                      {isErrorinIDT && <Option value={ "Cancelled in ERP" }>
                      Cancelled in ERP
                      </Option>}
                      <Option value=""></Option>
                    </Select>
                  </Form.Item>
                )}
                
                {currentOrder && (isSentforApproval) && isDisabled(userRole) && (
                  <Form.Item name="current_order_status" label="Order Status">
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder="Order Status"
                      style={{ width: '100%' }}
                      name="current_order_status"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => this.handleSelect(e)}
                    >
                      <Option value="Manually Cancelled">
                        Manually Cancelled
                      </Option>
                      <Option value=""></Option>
                    </Select>
                  </Form.Item>
                )}
                {currentOrder && (isEUShold) &&(isEUSholdApproval) && isDisabled(userRole) && isPermission(PERMISSIONS_BY_NAME['EUS Hold Release']) && (
                  <Form.Item name="current_order_status" label="Order Status">
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder="Order Status"
                      style={{ width: '100%' }}
                      name="current_order_status"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => this.handleSelect(e)}
                    >
                      <Option value="Manually Cancelled">
                        Manually Cancelled
                      </Option>
                    </Select>
                  </Form.Item>
                )}
                 {currentOrder &&
                   isEUShold && !isPermission(PERMISSIONS_BY_NAME['EUS Hold Release']) &&
                   isDisabled(userRole) && (
                  <Form.Item name="current_order_status" label="Order Status">
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      placeholder="Order Status"
                      style={{ width: '100%' }}
                      name="current_order_status"
                      disabled={isEUShold}
                      onChange={(e) => this.handleSelect(e)}
                    >
                      <Option value="Manually Cancelled">
                        Manually Cancelled
                      </Option>
                    </Select>
                  </Form.Item>
                )}
                {currentOrder &&
                  !isSentforApproval &&
                  !isrestrictedStatus &&
                  !isShippedFrom &&
                  !isErrorinDigiKey &&
                  !isEUSholdApproval&&
                  !isEUShold&&
                   isDisabled(userRole) && (
                    <Form.Item name="current_order_status" label="Order Status">
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        placeholder="Order Status"
                        style={{ width: '100%' }}
                        name="current_order_status"
                        disabled={
                          isUser && !isShippedFrom
                            ? !isDisabled(userRole) && !isAddPart
                            : false
                        }
                        onChange={(e) => this.handleSelect(e)}
                        // || currentOrder.current_order_status == 'Shipped from xxx')
                      >
                        {!isStatusBlank && (
                          <Option value="Order Created">Order Created</Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Sent for Approval">
                            Sent for Approval
                          </Option>
                        )}
                        {!isStatusBlank && !isDisabled(userRole) && (
                          <Option value="Pending for Approval">
                            Pending for Approval
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Approved">Approved</Option>
                        )}
                        {(!isStatusBlank || isSentforApproval) && (
                          <Option value="Not Approved">Not Approved</Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Sent to Digikey">
                            Sent to Digikey
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Sent to IDT SAP">
                            Sent to IDT SAP
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Sent to ISL Oracle">
                            Sent to ISL Oracle
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Sent to REL SAP">
                            Sent to REL SAP
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Order ACK received">
                            Order ACK received
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Backordered at Digikey">
                            Backordered at Digikey
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Error in DK">
                            Error in DK
                          </Option>
                        )}
                        {/* <Option value="Manual Hold">Manual Hold</Option> */}
                        {!isStatusBlank && (
                          <Option value="Shipped from Digikey">
                            Shipped from Digikey
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Shipped from IDT">
                            Shipped from IDT
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Shipped from ISL">
                            Shipped from ISL
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Shipped from REL">
                            Shipped from REL
                          </Option>
                        )}
                        {!isStatusBlank && (
                          <Option value="Cancelled in ERP">
                            Cancelled in ERP
                          </Option>
                        )}
                        {isStatusBlank && (
                          <Option value="Manually Cancelled">
                            Manually Cancelled
                          </Option>
                        )}
                        {isStatusBlank && (
                          <Option value="Cancelled by Digikey">
                            Cancelled by Digikey
                          </Option>
                        )}
                      </Select>
                    </Form.Item>
                  )}
                <Form.Item
                  label="Web Order"
                  name="web_order"
                  className={
                    isManuallyCancelled
                      ? ''
                      : iswebOrderValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter web order"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (iswebOrderValidated) {
                        iswebOrderValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  label="Web Order item"
                  name="web_order_itm"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 50,
                            message: `${validationMessage}50`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : iswebOrderItemValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter web order item"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (iswebOrderItemValidated) {
                        iswebOrderItemValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="MDLN Order"
                  name="mdln_order"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isMDLNorderValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 20,
                            message: `${validationMessage}20`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    onChange={() => {
                      if (isMDLNorderValidated) {
                        isMDLNorderValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                    placeholder="Enter MDLN Order"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="ERP Order Number"
                  name="erp_order_number"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isERPorderNumberValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 10,
                            message: `${validationMessage}10`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter ERP Order Number"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isERPorderNumberValidated) {
                        isERPorderNumberValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="ERP Order Item"
                  name="erp_order_item"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isERPorderItemValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 6,
                            message: `${validationMessage}6`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter ERP Order Item"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isERPorderItemValidated) {
                        isERPorderItemValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Channel"
                  name="channel"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isChannelValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 35,
                            message: `${validationMessage}35`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Channel"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isChannelValidated) {
                        isChannelValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}40`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  name="part_number"
                  label="Part Number"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isNewPartValidated || isPartValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Part number"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isPartValidated || isNewPartValidated) {
                        isPartValidated = false
                        isNewPartValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Sales Org Filter"
                  name="sales_org_filter"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isSalesOrgFilterValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 7,
                            message: `${validationMessage}7`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Sales Org Filter"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isSalesOrgFilterValidated) {
                        isSalesOrgFilterValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Order Qty"
                  name="order_qty"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isOrderQtyValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 10,
                            message: `${validationMessage}10`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Order Qty"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isOrderQtyValidated) {
                        isOrderQtyValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Approved Qty"
                  name="approved_qty"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isApproveQtyValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 10,
                            message: `${validationMessage}10`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Approved Qty"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isApproveQtyValidated) {
                        isApproveQtyValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Order Fulfill Company"
                  name="order_fulfill_company"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isOrderFulfillCompanyValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 3,
                            message: `${validationMessage}3`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Order Fulfill Company"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isOrderFulfillCompanyValidated) {
                        isOrderFulfillCompanyValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Disti/ERP Ack Qty"
                  name="disti_erp_ack_qty"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isDistiErpAckQtyValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 10,
                            message: `${validationMessage}10`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Disti/ERP Ack Qty"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isDistiErpAckQtyValidated) {
                        isDistiErpAckQtyValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="disti_erp_commit_date"
                  label="Disti/ERP Commit Date"
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    size="large"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.disti_erp_commit_date &&
                      moment(currentOrder.disti_erp_commit_date, DATE_FORMAT)
                    }
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Shipped Qty"
                  name="shipped_qty"
                  className={
                    isManuallyCancelled ? '' : isShippedQty ? 'req_error' : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 10,
                            message: `${validationMessage}10`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Shipped Qty"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isShippedQty) {
                        isShippedQty = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Disti/ERP Backorder Qty"
                  name="disti_erp_backorder_qty"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isDistiErpBackorderValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 10,
                            message: `${validationMessage}10`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Disti/ERP Backorder Qty"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isDistiErpBackorderValidated) {
                        isDistiErpBackorderValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Req Name"
                  name="req_name"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}40`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isReqNameValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Req Name"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isReqNameValidated) {
                        isReqNameValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Req email"
                  name="req_email"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 50,
                            message: `${validationMessage}50`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isreqEmailValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Req email "
                    type="email"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isreqEmailValidated) {
                        isreqEmailValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                {currentOrder ? (
                  <Form.Item
                    label="Req Tel.Number"
                    name="req_tel_number"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isreqTelNumValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isManuallyCancelled
                        ? [
                            // { min: 6, message: `Minimum length is 6.` },
                            // {
                            //   max: 30,
                            //   message: `${validationMessage}30`,
                            // },
                            // {
                            //   pattern: /^[\000-\177]*$/,
                            //   message: 'Non Western characters not allowed',
                            // },
                          ]
                        : [
                            {
                              pattern: new RegExp(
                                /^[0-9a-wA-Wy-zY-Z!@#$&()\-\`+,/\" ]*$/
                              ),
                              message:
                                'Enter Req Tel.Number in requested format.',
                            },
                            { min: 6, message: `Minimum length is 6.` },
                            {
                              max: 30,
                              message: `${validationMessage}30`,
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Req Tel.Number"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isreqTelNumValidated) {
                          isreqTelNumValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Req Tel.Number"
                    name="req_tel_number"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isreqTelNumValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isManuallyCancelled
                        ? [
                            // { min: 6, message: `Minimum length is 6.` },
                            // {
                            //   max: 30,
                            //   message: `${validationMessage}30`,
                            // },
                            // {
                            //   pattern: /^[\000-\177]*$/,
                            //   message: 'Non Western characters not allowed',
                            // },
                          ]
                        : [
                            {
                              required: true,
                              pattern: new RegExp(
                                /^[0-9a-wA-Wy-zY-Z!@#$&()\-\`+,/\" ]*$/
                              ),
                              message:
                                'Enter Req Tel.Number in requested format.',
                            },
                            { min: 6, message: `Minimum length is 6.` },
                            {
                              max: 20,
                              message: `${validationMessage}20`,
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Req Tel.Number"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isreqTelNumValidated) {
                          isreqTelNumValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )}
                <div>
                  <Popover content={content} trigger="hover">
                    <InfoCircleOutlined />
                  </Popover>
                </div>
                <Form.Item
                  label="Company Name"
                  name="company_name"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}40`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : iscompanyNameValidated
                      ? 'req_error'
                      : null
                  }
                >
                  <Input
                    placeholder="Enter Company Name"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (iscompanyNameValidated) {
                        iscompanyNameValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Shipto name"
                  name="shipto_name"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isShiptoNameValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}40`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Shipto name"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isShiptoNameValidated) {
                        isShiptoNameValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Shipto addr1"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isShiptoAddress1Validated
                      ? 'req_error'
                      : ''
                  }
                  name="shipto_addr1"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}40`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Shipto addr1"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isShiptoAddress1Validated) {
                        isShiptoAddress1Validated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Shipto addr2"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isShiptoAddress2Validated
                      ? 'req_error'
                      : ''
                  }
                  name="shipto_addr2"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}40`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Shipto addr2"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isShiptoAddress2Validated) {
                        isShiptoAddress2Validated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                {currentOrder && isManuallyCancelled ? (
                  <Form.Item
                    className={
                      isManuallyCancelled
                        ? ''
                        : isShiptoCityValidated
                        ? 'req_error'
                        : ''
                    }
                    label="Shipto city"
                    name="shipto_city"
                  >
                    <Input
                      placeholder="Enter Shipto city"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => {
                        if (isShiptoCityValidated) {
                          isShiptoCityValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                        // this.handleShiptoCityChange(e)
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    className={
                      isManuallyCancelled
                        ? ''
                        : isShiptoCityValidated
                        ? 'req_error'
                        : ''
                    }
                    label="Shipto city"
                    name="shipto_city"
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              required: true,
                              message: 'Please Enter Shipto city!',
                            },
                            {
                              max: 40,
                              message: `${validationMessage}40`,
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Shipto city"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => {
                        if (isShiptoCityValidated) {
                          isShiptoCityValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                        // this.handleShiptoCityChange(e)
                      }}
                    />
                  </Form.Item>
                )}
              </div>
              <div className="formitem_row">
                <Form.Item
                  className={
                    isManuallyCancelled
                      ? ''
                      : isShiptoStateValidated
                      ? 'req_error'
                      : ''
                  }
                  label="Shipto state"
                  name="shipto_state"
                  rules={
                    isManuallyCancelled
                      ? []
                      : isUSAshipto
                      ? [
                          {
                            required: true,
                            message: 'Please Enter Shipto state!',
                          },
                          {
                            max: 35,
                            message: `${validationMessage}35`,
                          },
                          {
                            whitespace: true,
                            message: 'Whitespace not allowed!',
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}40`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Shipto state"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isShiptoStateValidated) {
                        isShiptoStateValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className={
                    isManuallyCancelled
                      ? ''
                      : isShiptoStateISOValidated
                      ? 'req_error'
                      : ''
                  }
                  label="Shipto state ISO"
                  name="shipto_state_ISO"
                  rules={
                    isManuallyCancelled
                      ? []
                      : isUSAshipto
                      ? [
                          {
                            required: true,
                            message: 'Please Enter Shipto state ISO!',
                          },
                          {
                            max: 5,
                            message: `${validationMessage}5`,
                          },
                          {
                            whitespace: true,
                            message: 'Whitespace not allowed!',
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                      : [
                          {
                            max: 5,
                            message: `${validationMessage}5`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Shipto state ISO"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isShiptoStateISOValidated) {
                        isShiptoStateISOValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                {currentOrder && isManuallyCancelled ? (
                  <Form.Item
                    className={
                      isManuallyCancelled
                        ? ''
                        : isShiptoZipValidated
                        ? 'req_error'
                        : ''
                    }
                    label="Shipto zip"
                    name="shipto_zip"
                    // rules={[
                    //   {
                    //     max: 10,
                    //     message: `${validationMessage}10`,
                    //   },
                    //   {
                    //     pattern: /^[\000-\177]*$/,
                    //     message: 'Non Western characters not allowed',
                    //   },
                    //   {
                    //     whitespace: true,
                    //     message: 'Whitespace not allowed!',
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder="Enter Shipto Zip"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isShiptoZipValidated) {
                          isShiptoZipValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    className={
                      isManuallyCancelled
                        ? ''
                        : this.state.isHongKongShipTo
                        ? ''
                        : isShiptoZipValidated
                        ? 'req_error'
                        : ''
                    }
                    label="Shipto zip"
                    name="shipto_zip"
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              required: this.state.isHongKongShipTo
                                ? false
                                : true,
                              message: 'Please Enter Shipto Zip!',
                            },
                            {
                              max: 10,
                              message: `${validationMessage}10`,
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Shipto Zip"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isShiptoZipValidated) {
                          isShiptoZipValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )}
                {currentOrder && isManuallyCancelled ? (
                  <Form.Item
                    className={
                      isManuallyCancelled
                        ? ''
                        : isShiptoCountryValidated
                        ? 'req_error'
                        : ''
                    }
                    label="Shipto country"
                    name="shipto_country"
                    // dependencies={isSingapore ? ['company_country'] : null}
                    // rules={[
                    //   {
                    //     max: 15,
                    //     message: `${validationMessage}15`,
                    //   },
                    //   {
                    //     whitespace: true,
                    //     message: 'Whitespace not allowed!',
                    //   },
                    //   {
                    //     pattern: /^[\000-\177]*$/,
                    //     message: 'Non Western characters not allowed',
                    //   },
                    // ]}
                  >
                    <Input
                      maxLength={15}
                      placeholder="Enter Shipto country"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => {
                        if (isShiptoCountryValidated) {
                          isShiptoCountryValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                        // this.handleShiptoCountryChange(e)
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    className={
                      isManuallyCancelled
                        ? ''
                        : isShiptoCountryValidated
                        ? 'req_error'
                        : ''
                    }
                    label="Shipto country"
                    name="shipto_country"
                    // dependencies={isSingapore ? ['company_country'] : null}
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              required: true,
                              message: 'Please Enter Shipto country!',
                            },
                            {
                              max: 15,
                              message: `${validationMessage}15`,
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      maxLength={15}
                      placeholder="Enter Shipto country"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => {
                        if (isShiptoCountryValidated) {
                          isShiptoCountryValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                        // this.handleShiptoCountryChange(e)
                      }}
                    />
                  </Form.Item>
                )}
              </div>
              <div className="formitem_row">
                {currentOrder && isManuallyCancelled ? (
                  <Form.Item
                    label="Shipto country ISO"
                    name="shipto_country_ISO"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isShiptoCountryIsoValidated
                        ? 'req_error'
                        : ''
                    }
                  >
                    <Input
                      placeholder="Enter Shipto country ISO"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isShiptoCountryIsoValidated) {
                          isShiptoCountryIsoValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    className={
                      isManuallyCancelled
                        ? ''
                        : isShiptoCountryIsoValidated
                        ? 'req_error'
                        : ''
                    }
                    label="Shipto country ISO"
                    name="shipto_country_ISO"
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              required: true,
                              message: 'Please Enter Shipto country ISO!',
                            },
                            {
                              max: 2,
                              message: `${validationMessage}2`,
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Shipto country ISO"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isShiptoCountryIsoValidated) {
                          isShiptoCountryIsoValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label="Contact First Name"
                  name="contact_first_name"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 20,
                            message: `${validationMessage}20`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isContactFirstNameValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Contact First Name"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isContactFirstNameValidated) {
                        isContactFirstNameValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Contact Last Name"
                  name="contact_last_name"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isContactLastNameValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Contact Last Name"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isContactLastNameValidated) {
                        isContactLastNameValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Contact Email"
                  name="contact_email"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 50,
                            message: `${validationMessage}50`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : iscontactEmailValidated
                      ? 'req_error'
                      : null
                  }
                >
                  <Input
                    placeholder="Enter Contact Email"
                    size="large"
                    type="email"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (iscontactEmailValidated) {
                        iscontactEmailValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                {currentOrder ? (
                  <Form.Item
                    label="Contact Tel.Number"
                    name="contact_tel_number"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isContactTelNoValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isManuallyCancelled
                        ? [
                            // { min: 6, message: `Minimum length is 6.` },
                            // {
                            //   max: 30,
                            //   message: `${validationMessage}30`,
                            // },
                            // {
                            //   pattern: /^[\000-\177]*$/,
                            //   message: 'Non Western characters not allowed',
                            // },
                          ]
                        : [
                            {
                              pattern: new RegExp(
                                /^[0-9a-wA-Wy-zY-Z!@#$&()\-\`+,/\" ]*$/
                              ),
                              message:
                                'Enter Contact Tel.Number in requested format.',
                            },
                            { min: 6, message: `Minimum length is 6.` },
                            {
                              max: 30,
                              message: `${validationMessage}30`,
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Contact Tel.Number"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isContactTelNoValidated) {
                          isContactTelNoValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Contact Tel.Number"
                    name="contact_tel_number"
                    // className= {isError1 ? "req_error" : ""}
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              required: true,
                              message: 'Enter Contact Tel.Number',
                              pattern: new RegExp(
                                /^[0-9a-wA-Wy-zY-Z!@#$&()\-\`+,/\" ]*$/
                              ),
                              message: 'Input number in requested format.',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                            { min: 6, message: `Minimum length is 6.` },
                            {
                              max: 15,
                              message: `${validationMessage}15`,
                            },
                          ]
                    }
                    className={
                      isManuallyCancelled
                        ? ''
                        : isContactTelNoValidated
                        ? 'req_error'
                        : ''
                    }
                  >
                    <Input
                      placeholder="Enter Contact Tel.Number"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isContactTelNoValidated) {
                          isContactTelNoValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )}
                <div>
                  <Popover content={content} trigger="hover">
                    <InfoCircleOutlined />
                  </Popover>
                </div>
                {isChina ? (
                  <Form.Item
                    label="USCI"
                    name="USCI"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isUSCIvalidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              max: 35,
                              message: `${validationMessage}35`,
                            },
                            {
                              required: true,
                              message: 'Please enter USCI!',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter USCI"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isUSCIvalidated) {
                          isUSCIvalidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="USCI"
                    name="USCI"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isUSCIvalidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              max: 35,
                              message: `${validationMessage}35`,
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter USCI"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isUSCIvalidated) {
                          isUSCIvalidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )}
              </div>
              <div className="formitem_row">
                <Form.Item
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}40`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  name="company_location"
                  label="Company Location"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isCompanyLocationValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Company Location"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isCompanyLocationValidated) {
                        isCompanyLocationValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}35`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  name="company_addr1"
                  label="Company Address1"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isCompanyAddress1Validated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Company Address1"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isCompanyAddress1Validated) {
                        isCompanyAddress1Validated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Company Address 2"
                  name="company_addr2"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isCompanyAddress2Validated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 40,
                            message: `${validationMessage}35`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Company Address 2"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isCompanyAddress2Validated) {
                        isCompanyAddress2Validated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                {currentOrder && isManuallyCancelled ? (
                  <Form.Item
                    rules={
                      [
                        // {
                        //   max: 40,
                        //   message: `${validationMessage}40`,
                        // },
                        // {
                        //   whitespace: true,
                        //   message: 'Whitespace not allowed!',
                        // },
                        // {
                        //   pattern: /^[\000-\177]*$/,
                        //   message: 'Non Western characters not allowed',
                        // },
                      ]
                    }
                    name="company_city"
                    label="Company City"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyCityValidated
                        ? 'req_error'
                        : ''
                    }
                  >
                    <Input
                      placeholder="Enter Company City"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => {
                        if (isCompanyCityValidated) {
                          isCompanyCityValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                        // this.handleComapanyCity(e)
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              required: true,
                              message: 'Please Enter Company City!',
                            },
                            {
                              max: 40,
                              message: `${validationMessage}40`,
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                    name="company_city"
                    label="Company City"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyCityValidated
                        ? 'req_error'
                        : ''
                    }
                  >
                    <Input
                      placeholder="Enter Company City"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => {
                        if (isCompanyCityValidated) {
                          isCompanyCityValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                        // this.handleComapanyCity(e)
                      }}
                    />
                  </Form.Item>
                )}
              </div>
              <div className="formitem_row">
                {currentOrder && isManuallyCancelled ? (
                  <Form.Item
                    label="Company State"
                    name="company_state"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyStateValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isUSAcountry
                        ? [
                            // {
                            //   required: true,
                            //   message: 'Please Enter Company State!',
                            // },
                            // {
                            //   max: 35,
                            //   message: `${validationMessage}35`,
                            // },
                            // {
                            //   pattern: /^[\000-\177]*$/,
                            //   message: 'Non Western characters not allowed',
                            // },
                            // {
                            //   whitespace: true,
                            //   message: 'Whitespace not allowed!',
                            // },
                          ]
                        : [
                            // {
                            //   max: 35,
                            //   message: `${validationMessage}35`,
                            // },
                            // {
                            //   pattern: /^[\000-\177]*$/,
                            //   message: 'Non Western characters not allowed',
                            // },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Company State"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isCompanyStateValidated) {
                          isCompanyStateValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Company State"
                    name="company_state"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyStateValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isManuallyCancelled
                        ? []
                        : isUSAcountry
                        ? [
                            {
                              required: true,
                              message: 'Please Enter Company State!',
                            },
                            {
                              max: 35,
                              message: `${validationMessage}35`,
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                        : [
                            {
                              max: 35,
                              message: `${validationMessage}35`,
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Company State"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isCompanyStateValidated) {
                          isCompanyStateValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label="Company state ISO"
                  name="company_state_ISO"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isCompanyStateISOValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : isUSAcountry
                      ? [
                          {
                            required: true,
                            message: 'Please Enter Company state ISO!',
                          },
                          {
                            max: 5,
                            message: `${validationMessage}5`,
                          },
                          {
                            whitespace: true,
                            message: 'Whitespace not allowed!',
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                      : [
                          {
                            max: 5,
                            message: `${validationMessage}5`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Company state ISO"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isCompanyStateISOValidated) {
                        isCompanyStateISOValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                {currentOrder && isManuallyCancelled ? (
                  <Form.Item
                    label="Company Zip"
                    name="company_zip"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyZipValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      [
                        // {
                        //   max: 10,
                        //   message: `${validationMessage}10`,
                        // },
                        // {
                        //   pattern: /^[\000-\177]*$/,
                        //   message: 'Non Western characters not allowed',
                        // },
                        // {
                        //   whitespace: true,
                        //   message: 'Whitespace not allowed!',
                        // },
                      ]
                    }
                  >
                    <Input
                      placeholder="Enter Company Zip"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isCompanyZipValidated) {
                          isCompanyZipValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Company Zip"
                    name="company_zip"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyZipValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              required: this.state.isHongKongComp
                                ? false
                                : true,
                              message: 'Please Enter Company Zip!',
                            },
                            {
                              max: 10,
                              message: `${validationMessage}10`,
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Company Zip"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isCompanyZipValidated) {
                          isCompanyZipValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )}
                {currentOrder && isManuallyCancelled ? (
                  <Form.Item
                    label="Company Country"
                    name="company_country"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyCountryValidated
                        ? 'req_error'
                        : ''
                    }
                    // dependencies={isSingapore ? ['shipto_country'] : null}
                    rules={
                      [
                        // {
                        //   max: 15,
                        //   message: `${validationMessage}2`,
                        // },
                        // {
                        //   whitespace: true,
                        //   message: 'Whitespace not allowed!',
                        // },
                        // {
                        //   pattern: /^[\000-\177]*$/,
                        //   message: 'Non Western characters not allowed',
                        // },
                      ]
                    }
                  >
                    <Input
                      maxLength={15}
                      placeholder="Enter Company Country"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => {
                        // this.handleComapanyCountry(e)
                        if (isCompanyCountryValidated) {
                          isCompanyCountryValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Company Country"
                    name="company_country"
                    // dependencies={isSingapore ? ['shipto_country'] : null}
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyCountryValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              required: true,
                              message: 'Please Enter Company Country!',
                            },
                            {
                              max: 15,
                              message: `${validationMessage}2`,
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      maxLength={15}
                      placeholder="Enter Company Country"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={(e) => {
                        // this.handleComapanyCountry(e)
                        if (isCompanyCountryValidated) {
                          isCompanyCountryValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )}
              </div>
              <div className="formitem_row">
                {currentOrder && isManuallyCancelled ? (
                  <Form.Item
                    label="Company country ISO"
                    name="company_country_ISO"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyCountryIsoValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      [
                        // {
                        //   max: 5,
                        //   message: `${validationMessage}5`,
                        // },
                        // {
                        //   whitespace: true,
                        //   message: 'Whitespace not allowed!',
                        // },
                        // {
                        //   pattern: /^[\000-\177]*$/,
                        //   message: 'Non Western characters not allowed',
                        // },
                      ]
                    }
                  >
                    <Input
                      placeholder="Enter Company country ISO"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isCompanyCountryIsoValidated) {
                          isCompanyCountryIsoValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Company country ISO"
                    name="company_country_ISO"
                    className={
                      isManuallyCancelled
                        ? ''
                        : isCompanyCountryIsoValidated
                        ? 'req_error'
                        : ''
                    }
                    rules={
                      isManuallyCancelled
                        ? []
                        : [
                            {
                              required: true,
                              message: 'Please Enter Company country ISO!',
                            },
                            {
                              max: 5,
                              message: `${validationMessage}5`,
                            },
                            {
                              whitespace: true,
                              message: 'Whitespace not allowed!',
                            },
                            {
                              pattern: /^[\000-\177]*$/,
                              message: 'Non Western characters not allowed',
                            },
                          ]
                    }
                  >
                    <Input
                      placeholder="Enter Company country ISO"
                      size="large"
                      disabled={
                        isUser ? !isDisabled(userRole) && !isAddPart : false
                      }
                      onChange={() => {
                        if (isCompanyCountryIsoValidated) {
                          isCompanyCountryIsoValidated = false
                          this.setState({
                            ...this.state,
                            isMDLNflag: Math.random() * 10000,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label="Company website"
                  name="company_website"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isCompanyWebsiteValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Company Website"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Disti/ERP Error Reason"
                  name="disti_erp_error_reason"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isDistiErpReasonValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 255,
                            message: `${validationMessage}255`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Disti/ERP Error Reason"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isDistiErpReasonValidated) {
                        isDistiErpReasonValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item label="RORE Man Reject" name="rore_man_reject">
                  <Select
                    defaultValue="Y"
                    style={{ width: '100%' }}
                    className={isROREmanRejectValidated ? 'req_error' : ''}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="RORE Order Reject Username"
                  name="rore_order_rej_username"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isROREorderRejectUsernameValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 35,
                            message: `${validationMessage}35`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Disti/ERP Backorder Qty"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isROREorderRejectUsernameValidated) {
                        isROREorderRejectUsernameValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Non Approve Reason"
                  name="non_approv_reason"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isNoneApproveReasonValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Non Approve Reason"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isNoneApproveReasonValidated) {
                        isNoneApproveReasonValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Disti Ship Source"
                  name="disti_ship_source"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isDistiShipSourceValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 12,
                            message: `${validationMessage}12`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Disti Ship Source"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isDistiShipSourceValidated) {
                        isDistiShipSourceValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Ship Type"
                  name="ship_type"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isShipTypeValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 35,
                            message: `${validationMessage}35`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Ship Type"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isShipTypeValidated) {
                        isShipTypeValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Carrier Name"
                  name="carrier_name"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isCarrierNameValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 35,
                            message: `${validationMessage}35`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Carrier Name"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isCarrierNameValidated) {
                        isCarrierNameValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Waybill Number"
                  name="waybill"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isWaybillValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 35,
                            message: `${validationMessage}35`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Waybill Number"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isWaybillValidated) {
                        isWaybillValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Packlist"
                  name="packlist"
                  className={
                    isManuallyCancelled
                      ? ''
                      : ispacklistValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 35,
                            message: `${validationMessage}35`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Packlist"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (ispacklistValidated) {
                        ispacklistValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Order Ship Company"
                  name="order_ship_company"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isOrderShipCompanyValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 3,
                            message: `${validationMessage}3`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Order Ship Company"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isOrderShipCompanyValidated) {
                        isOrderShipCompanyValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Opp number"
                  name="Opp_number"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isOppNumberValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 20,
                            message: `${validationMessage}20`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Opp number"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isOppNumberValidated) {
                        isOppNumberValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="EAU"
                  name="eau"
                  className={
                    isManuallyCancelled ? '' : isEAUvalidated ? 'req_error' : ''
                  }
                  rules={[
                    {
                      max: 10,
                      message: `${validationMessage}10`,
                    },
                    {
                      pattern: /^[\000-\177]*$/,
                      message: 'Non Western characters not allowed',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter EAU"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isEAUvalidated) {
                        isEAUvalidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  label="Program"
                  name="program"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isProgramValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 80,
                            message: `${validationMessage}80`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Program"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isProgramValidated) {
                        isProgramValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className={
                    isManuallyCancelled
                      ? ''
                      : isTargetAppValidated
                      ? 'req_error'
                      : ''
                  }
                  label="Target App"
                  name="target_app"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 50,
                            message: `${validationMessage}50`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Target App"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isTargetAppValidated) {
                        isTargetAppValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  className={
                    isManuallyCancelled
                      ? ''
                      : isEndProductValidated
                      ? 'req_error'
                      : ''
                  }
                  label="End product"
                  name="end_product"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 50,
                            message: `${validationMessage}50`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter End product"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isEndProductValidated) {
                        isEndProductValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item label="Itar" name="itar">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Itar"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="Controlled Part" name="controlled_part">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Controlled Part"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Bypass Disti" name="bypass_disti">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Bypass Disti"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="Can disti Ship" name="can_disti_ship">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Can disti Ship"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="BR Name Check" name="br_name_check">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR Name Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="BR Part Check" name="br_part_check">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR Part Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="BR Itar Check" name="br_itar_check">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR Itar Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="BR Export Countrol Check"
                  name="br_export_cntrl_check"
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR Export Countrol Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="BR BU Check" name="br_bu_check">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR BU Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="BR Div Check" name="br_div_check">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR Div Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="BR Mkt Group Check" name="br_mkt_grp_check">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR Mkt Group Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="BR Coo Check" name="br_coo_check">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR Coo Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="BR Channel Check" name="br_channel_check">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR Channel Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  label="BR Ship-to Name/Country Check"
                  name="br_shipto_name_check"
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="BR Ship-to Name/Country Check"
                    style={{ width: '100%' }}
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Pass">Pass</Option>
                    <Option value="Fail">Fail</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Core Inv Qty"
                  name="core_inv_qty"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 10,
                            message: `${validationMessage}10`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled ? '' : isCoreInvQty ? 'req_error' : ''
                  }
                >
                  <Input
                    placeholder="Enter Core Inv Qty"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isCoreInvQty) {
                        isCoreInvQty = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  label="Disti Part Not Found"
                  name="disti_part_not_found"
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Disti Part Not Found"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="International Shipment"
                  name="intl_Shipment"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 35,
                            message: `${validationMessage}35`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isIntlShipmentValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Disti/ERP Backorder Qty"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isIntlShipmentValidated) {
                        isIntlShipmentValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Grouping ID"
                  name="grouping_ID"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isGroupIdValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 8,
                            message: `${validationMessage}8`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Grouping ID"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isGroupIdValidated) {
                        isGroupIdValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Order Reproc Username"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isOrderRepocUsernameValidated
                      ? 'req_error'
                      : ''
                  }
                  name="order_reproc_username"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 50,
                            message: `${validationMessage}50`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Order Reproc Username"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isOrderRepocUsernameValidated) {
                        isOrderRepocUsernameValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  label="Manually Changed Username"
                  name="manually_changed_username"
                >
                  <Input
                    placeholder="Enter Manually Changed Username"
                    size="large"
                    disabled={true}
                  />
                </Form.Item>

                <Form.Item
                  label="Comments"
                  name="comments"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isCommentsValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 255,
                            message: `${validationMessage}255`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Comments"
                    size="large"
                    disabled={
                      isUser ? !isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isCommentsValidated) {
                        isCommentsValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="consign_ship_conf_sent_date"
                  label="Consign Ship Conf Sent Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.consign_ship_conf_sent_date &&
                      moment(
                        currentOrder.consign_ship_conf_sent_date,
                        DATE_FORMAT
                      )
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="consign_ship_conf_sent_time"
                  label="Consign Ship Conf Sent Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.consign_ship_conf_sent_time &&
                      moment(
                        currentOrder.consign_ship_conf_sent_time,
                        TIME_FORMAT
                      )
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="delay_timer_expiration_date"
                  label="Delay Timer Expiration Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.delay_timer_expiration_date &&
                      moment(
                        currentOrder.delay_timer_expiration_date,
                        DATE_FORMAT
                      )
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="disti_erp_ack_rec_date"
                  label="Disti/ERP Ack Rec Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.disti_erp_ack_rec_date &&
                      moment(currentOrder.disti_erp_ack_rec_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="disti_erp_ack_rec_time"
                  label="Disti/ERP Ack Rec Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.disti_erp_ack_rec_time &&
                      moment(currentOrder.disti_erp_ack_rec_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="disti_erp_backorder_rec_date"
                  label="Disti/ERP Backorder Rec Date"
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    size="large"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.disti_erp_backorder_rec_date &&
                      moment(
                        currentOrder.disti_erp_backorder_rec_date,
                        DATE_FORMAT
                      )
                    }
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="disti_erp_backorder_rec_time"
                  label="Disti/ERP Backorder Rec Time"
                >
                  <TimePicker
                    placeholder="HH:mm:ss"
                    size="large"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.disti_erp_backorder_rec_time &&
                      moment(
                        currentOrder.disti_erp_backorder_rec_time,
                        TIME_FORMAT
                      )
                    }
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="disti_erp_error_rec_date"
                  label="Disti/ERP Error Rec Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.disti_erp_error_rec_date &&
                      moment(currentOrder.disti_erp_error_rec_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="disti_erp_error_rec_time"
                  label="Disti/ERP Error Rec Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.disti_erp_error_rec_time &&
                      moment(currentOrder.disti_erp_error_rec_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item name="dup_date" label="Duplicate Date">
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.dup_date &&
                      moment(currentOrder.dup_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item name="dup_time" label="Duplicate Time">
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.dup_time &&
                      moment(currentOrder.dup_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="intl_shipment_start_date"
                  label="International Shipment Start Date"
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    size="large"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.intl_shipment_start_date &&
                      moment(currentOrder.intl_shipment_start_date, DATE_FORMAT)
                    }
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  name="intl_shipment_start_time"
                  label="International Shipment Start Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.intl_shipment_start_time &&
                      moment(currentOrder.intl_shipment_start_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item name="lead_to_eloq_date" label="Lead To Eloq Date">
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ship_ack_to_cust_time &&
                      moment(currentOrder.ship_ack_to_cust_time, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item name="lead_to_eloq_time" label="Lead To Eloq Time">
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.lead_to_eloq_time &&
                      moment(currentOrder.lead_to_eloq_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="manually_changed_date"
                  label="Manually Changed Date"
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    size="large"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.manually_changed_date &&
                      moment(currentOrder.manually_changed_date, DATE_FORMAT)
                    }
                    disabled={true}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="manually_changed_time"
                  label="Manually Changed Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.manually_changed_time &&
                      moment(currentOrder.manually_changed_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  name="mdln_approv_rej_date"
                  label="MDLN Approve Reject Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.mdln_approv_rej_date &&
                      moment(currentOrder.mdln_approv_rej_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="mdln_approv_rej_time"
                  label="MDLN Approve Reject Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.mdln_approv_rej_time &&
                      moment(currentOrder.mdln_approv_rej_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="ord_ack_to_cust_date"
                  label="Order Ack To Customer Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ord_ack_to_cust_date &&
                      moment(currentOrder.ord_ack_to_cust_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="ord_ack_to_cust_time"
                  label="Order Ack To Customer Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ord_ack_to_cust_time &&
                      moment(currentOrder.ord_ack_to_cust_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="ord_cancel_to_cust_date"
                  label="Order Cancel To Customer Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ord_cancel_to_cust_date &&
                      moment(currentOrder.ord_cancel_to_cust_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  name="ord_cancel_to_cust_time"
                  label="Order Cancel To Customer Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ord_cancel_to_cust_time &&
                      moment(currentOrder.ord_cancel_to_cust_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="ord_conf_to_cust_date"
                  label="'Order Conf To Customer Date"
                >
                  <DatePicker
                    getpopupcontainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ord_conf_to_cust_date &&
                      moment(currentOrder.ord_conf_to_cust_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="ord_conf_to_cust_time"
                  label="Order Conf To Customer Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ord_conf_to_cust_time &&
                      moment(currentOrder.ord_conf_to_cust_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="ord_rej_to_cust_date"
                  label="'Order Rej To Customer Date"
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ord_rej_to_cust_date &&
                      moment(currentOrder.ord_rej_to_cust_date, DATE_FORMAT)
                    }
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="ord_rej_to_cust_time"
                  label="Order Rej To Customer Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ord_rej_to_cust_time &&
                      moment(currentOrder.ord_rej_to_cust_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="order_fulfill_sent_date"
                  label="Order Fulfill Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.order_fulfill_sent_date &&
                      moment(currentOrder.order_fulfill_sent_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="order_fulfill_sent_time"
                  label="Order Fulfill Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.order_fulfill_sent_time &&
                      moment(currentOrder.order_fulfill_sent_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>

                <Form.Item name="order_reproc_date" label="Order Reproc Date">
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.order_reproc_date &&
                      moment(currentOrder.order_reproc_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item name="order_reproc_time" label="Order Reproc Time">
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.order_reproc_time &&
                      moment(currentOrder.order_reproc_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="rore_order_creat_date"
                  label="RORE Order Created Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.rore_order_creat_date &&
                      moment(currentOrder.rore_order_creat_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  name="rore_order_creat_time"
                  label="RORE Order Created Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.rore_order_creat_time &&
                      moment(currentOrder.rore_order_creat_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="rore_order_modified_date"
                  label="RORE Order Modified Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.rore_order_modified_date &&
                      moment(currentOrder.rore_order_modified_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  name="rore_order_modified_time"
                  label="RORE Order Modified Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.rore_order_modified_time &&
                      moment(currentOrder.rore_order_modified_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="rore_order_rej_date"
                  label="RORE Order Reject Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.rore_order_rej_date &&
                      moment(currentOrder.rore_order_rej_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  name="rore_order_rej_time"
                  label="RORE Order Reject Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.rore_order_rej_time &&
                      moment(currentOrder.rore_order_rej_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item name="sent_to_mdln_date" label="Sent To MDLN DATE">
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.sent_to_mdln_date &&
                      moment(currentOrder.sent_to_mdln_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item name="sent_to_mdln_time" label="Sent To MDLN Time">
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.sent_to_mdln_time &&
                      moment(currentOrder.sent_to_mdln_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="ship_ack_to_cust_date"
                  label="Ship Ack To Customer Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ship_ack_to_cust_date &&
                      moment(currentOrder.ship_ack_to_cust_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  name="ship_ack_to_cust_time"
                  label="Ship Ack To Customer Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ship_ack_to_cust_time &&
                      moment(currentOrder.ship_ack_to_cust_date, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item name="ship_conf_date" label="Ship Conf Date">
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ship_conf_date &&
                      moment(currentOrder.ship_conf_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item name="ship_conf_time" label="Ship Conf Time">
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ship_conf_time &&
                      moment(currentOrder.ship_conf_time, DATE_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="ship_conf_mdln_sent_date"
                  label="Ship Conf MDLN Sent Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ship_conf_mdln_sent_date &&
                      moment(currentOrder.ship_conf_mdln_sent_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  name="ship_conf_mdln_sent_time"
                  label="Ship Conf MDLN Sent Time"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ship_conf_mdln_sent_time &&
                      moment(currentOrder.ship_conf_mdln_sent_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item name="web_eoh_call_date" label="Web Eoh Call Date">
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.web_eoh_call_date &&
                      moment(currentOrder.web_eoh_call_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item name="web_eoh_cal_time" label="Web Eoh Cal Time">
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.web_eoh_cal_time &&
                      moment(currentOrder.web_eoh_cal_time, TIME_FORMAT)
                    }
                    placeholder="HH:mm:ss"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="ord_ack_to_cust_date"
                  label="Order Ack To Customer Date"
                >
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.ord_ack_to_cust_date &&
                      moment(currentOrder.ord_ack_to_cust_date, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  label="Consign Inv Qty"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isConsignInvQtyValidated
                      ? 'req_error'
                      : ''
                  }
                  name="consign_inv_qty"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            max: 10,
                            message: `${validationMessage}10`,
                          },
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Consign Inv Qty"
                    size="large"
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                    onChange={() => {
                      if (isConsignInvQtyValidated) {
                        isConsignInvQtyValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Parent company"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isParentCompanyValidated
                      ? 'req_error'
                      : ''
                  }
                  name="parent_company"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                >
                  <Input
                    placeholder="Enter Parent company"
                    size="large"
                    onChange={() => {
                      if (isParentCompanyValidated) {
                        isParentCompanyValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item label="Military use" name="military_use">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Military use"
                    style={{ width: '100%' }}
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Business purpose"
                  name="business_purpose"
                  className={
                    isManuallyCancelled
                      ? ''
                      : isBusinessPurposeValidated
                      ? 'req_error'
                      : ''
                  }
                  rules={[
                    {
                      pattern: /^[\000-\177]*$/,
                      message: 'Non Western characters not allowed',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Business purpose"
                    size="large"
                    onChange={() => {
                      if (isBusinessPurposeValidated) {
                        isBusinessPurposeValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="Requestor company"
                  name="requestor_company"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isRequestorCompanyValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter Requestor company"
                    size="large"
                    onChange={() => {
                      if (isRequestorCompanyValidated) {
                        isRequestorCompanyValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="disti_erp_backorder_rec_date"
                  label="Disti/ERP Backorder Rec Date"
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    size="large"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.disti_erp_backorder_rec_date &&
                      moment(
                        currentOrder.disti_erp_backorder_rec_date,
                        DATE_FORMAT
                      )
                    }
                    disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item
                  label="DPL Released By"
                  name="dpl_released_by"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isDPLByValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter DPL Released By"
                    size="large"
                    disabled={true}
                    onChange={() => {
                      if (isDPLByValidated) {
                        isDPLByValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name="dpl_released_on" label="DPL Released On">
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.dpl_released_on &&
                      moment(currentOrder.dpl_released_on, DATE_FORMAT)
                    }
                    placeholder="YYYY-MM-DD"
                    size="large"
                    disabled={true}
                  />
                </Form.Item>
              </div>

              <div className="formitem_row">
                <Form.Item
                  label="DPL Released At"
                  name="dpl_released_at"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isDPLAtValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter DPL Released At"
                    size="large"
                    disabled={true}
                    onChange={() => {
                      if (isDPLAtValidated) {
                        isDPLAtValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="End Customer Location"
                  name="end_customer_location"
                >
                  <Input
                    placeholder="Enter End Customer Location"
                    size="large"
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="ModelN Approver" name="modeln_approver">
                  <Input placeholder="Enter ModelN Approver" size="large" />
                </Form.Item>
                <Form.Item label="End Customer Name" name="end_customer">
                  <Input placeholder="Enter End Customer Name" size="large" />
                </Form.Item>
              </div>
              <div className="formitem_row">
                <Form.Item label="End Customer Parent ID" name="end_customer_parent_ID">
                  <Input 
                  placeholder="Enter End Customer Parent ID" 
                  disabled={
                      isUser ? isDisabled(userRole) && !isAddPart : false
                    }
                  size="large" />
                </Form.Item>
                <Form.Item label="Planner" name="planner">
                  <Input placeholder="Enter Planner" size="large" />
                </Form.Item>
               
              </div>
              <div className="formitem_row">
              <Form.Item label="Plant" name="plant">
                  <Input placeholder="Enter Plant" size="large" />
                </Form.Item>
                <Form.Item label="Cancellation Flag" name="cancellation_flag">
                  <Input placeholder="Enter Cancellation Flag" size="large" />
                </Form.Item>
              </div>
              <div className="formitem_row">
              <Form.Item label="EUS Hold" name="eus_hold">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="EUS Hold"
                    style={{ width: '100%' }}
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Auto Approve Disable" name="auto_approve_disable">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placeholder="Auto Approve Disable"
                    style={{ width: '100%' }}
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="formitem_row">
              <Form.Item
                  label="EUS Hold Released By"
                  name="eus_hold_released_by"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isEUSHoldByValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter EUS Hold Released By"
                    size="large"
                    disabled={true}
                    onChange={() => {
                      if (isEUSHoldByValidated) {
                        isEUSHoldByValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              <Form.Item
                  name="eus_hold_released_on"
                  label="EUS Hold Released On"
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    size="large"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.eus_hold_released_on &&
                      moment(currentOrder.eus_hold_released_on, DATE_FORMAT)
                    }
                    disabled={true}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
              <Form.Item
                  label="EUS Hold Released At"
                  name="eus_hold_released_at"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isEUSHoldAtValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter EUS Hold Released At"
                    size="large"
                    disabled={true}
                    onChange={() => {
                      if (isEUSHoldAtValidated) {
                        isEUSHoldAtValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              {currentOrder && (isManuallyCancelled || isCancelValid) ? (
                  <Form.Item
                    label="Reason for cancellation"
                    name="reason_for_cancellation"
                    className={
                      isManuallyCancelledValidated || isOrderStatusCanceled
                        ? 'req_error'
                        : ''
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Enter Reason for cancellation',
                      },
                      {
                        max: 132,
                        message: `${validationMessage}132`,
                      },
                      {
                        pattern: /^[\000-\177]*$/,
                        message: 'Non Western characters not allowed',
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Enter Reason for cancellation"
                      size="large"
                      rows={2}
                    />
                  </Form.Item>
                ) : currentOrder ? (
                  <Form.Item
                    label="Reason for cancellation"
                    name="reason_for_cancellation"
                    rules={[
                      {
                        max: 132,
                        message: `${validationMessage}132`,
                      },
                      {
                        pattern: /^[\000-\177]*$/,
                        message: 'Non Western characters not allowed',
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Enter Reason for cancellation"
                      size="large"
                      rows={2}
                    />
                  </Form.Item>
                ) : null}
              </div>
              {/* <div className="formitem_row">
              <Form.Item
                  name="s4_order_ack_mdln_receive_date"
                  label="S4 Order Ack Mdln Receive Date"
                  
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    size="large"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.s4_order_ack_mdln_receive_date &&
                      moment(currentOrder.s4_order_ack_mdln_receive_date, DATE_FORMAT)
                    }
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  name="s4_order_ack_mdln_receive_time"
                  label="S4 Order Ack Mdln Receive Time"
                   rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isS4OrderAckMdlnReceiveTimeValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter S4 Order Ack Mdln Receive Time"
                    size="large"
                    disabled={true}
                    onChange={() => {
                      if (isS4OrderAckMdlnReceiveTimeValidated) {
                        isS4OrderAckMdlnReceiveTimeValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className="formitem_row">
              <Form.Item
                  name="s4_ship_conf_mdln_receive_date"
                  label="S4 Ship Conf Mdln Receive Date"
                >
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    size="large"
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    defaultValue={
                      currentOrder &&
                      currentOrder.s4_ship_conf_mdln_receive_date &&
                      moment(currentOrder.s4_ship_conf_mdln_receive_date, DATE_FORMAT)
                    }
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  name="s4_ship_conf_mdln_receive_time"
                  label="S4 Ship Conf Mdln Receive Time"
                  rules={
                    isManuallyCancelled
                      ? []
                      : [
                          {
                            pattern: /^[\000-\177]*$/,
                            message: 'Non Western characters not allowed',
                          },
                        ]
                  }
                  className={
                    isManuallyCancelled
                      ? ''
                      : isS4ShipConfMdlnReceiveTimeValidated
                      ? 'req_error'
                      : ''
                  }
                >
                  <Input
                    placeholder="Enter S4 Ship Conf Mdln Receive Time"
                    size="large"
                    disabled={true}
                    onChange={() => {
                      if (isS4ShipConfMdlnReceiveTimeValidated) {
                        isS4ShipConfMdlnReceiveTimeValidated = false
                        this.setState({
                          ...this.state,
                          isMDLNflag: Math.random() * 10000,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </div> */}
              <div className="formitem_action_row" id="bottom">
                <Form.Item>
                  {currentOrder &&
                  currentOrder.web_order &&
                  isPermission(
                    PERMISSIONS_BY_NAME[('Edit DPL', 'View DPL')]
                  ) ? (
                    <Button
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/dplScreening',
                          search: `?web_order=${
                            currentOrder ? currentOrder.web_order : ''
                          }`,
                          // state: { detail: response.data }
                        })
                      }}
                      size="large"
                    >
                      DPL Screening Logs
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => this.props.setFormStatus('', '')}
                    size="large"
                  >
                    Back
                  </Button>
                  {isPermission(
                    PERMISSIONS_BY_NAME[
                      ('Add sample order', 'Manage Sample Order')
                    ]
                  ) && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                    >
                      Save
                    </Button>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
          <div className="fixed_scroll_button">
            <Button
              type="primary"
              className="fixed_button"
              size="large"
              onClick={() => {
                var elmnt = document.getElementById('bottom')
                elmnt.scrollIntoView()
              }}
            >
              {' '}
              Scroll to bottom
              <ArrowDownOutlined />
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderForm
