import React, { useContext, useState, useEffect, useRef, Fragment } from 'react'
import moment from 'moment'
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import {
  Table,
  Input,
  Button,
  Popconfirm,
  Form,
  message,
  Spin,
  Space,
} from 'antd'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import './DPLscreeningTable.scss'
import { HTTPService } from '../../otherServices/apiService'
import {
  DATE_FORMAT,
  CATALOG_SORTING,
  PERMISSIONS_BY_NAME,
  ORDER_SORTING,
} from '../../otherServices/constant'
import DPLscreeningForm from './DPLscreeningForm'
import {
  exportTextFile,
  downloadExcel,
  trimSpace,
  isPermission,
} from '../../otherServices/commonFunction'

const EditableContext = React.createContext()
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const form = useContext(EditableContext)
  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async (e) => {
    try {
      const values = await form.validateFields()
      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 20,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

class DPLscreeningTable extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    })
  }

  // handleReset = (clearFilters) => {
  //   clearFilters()
  //   this.setState({ searchText: '' })
  // }

  //table column properties
  constructor(props) {
    super(props)
    this.goBack = this.goBack.bind(this)
    this.state = {
      dataSource: null,
      count: 0,
      currentPage: 1,
      tableData: [],
      isFormOpen: false,
      currentData: null,
      loader: false,
      searchText: null,
      filterApplied: null,
      isAddPart: false,
      filterValueArray: [],
      isFilter: false,
      pageLimit: 20,
      filterString: '',
      selectAllExportData: [],
      exportURL: '',
      highlightFilterArray: [],
      selectedLog: null,
      exportAllData: [],
    }
  }

  componentDidMount() {
    this.fetchData(
      this.props &&
        this.props.history.location &&
        this.props.history.location.search
    )
    this.setState({ searchText: this.props.history.location.search })
  }

  //function to fetch table data
  fetchData = async (searchValue = '') => {
    this.setState({ loader: true, highlightFilterArray: [] })
    let urlLink
    if (
      this.props &&
      this.props.history.location &&
      this.props.history.location.search &&
      this.state.searchText != null
    ) {
      urlLink = `dpl-screening/${this.props.history.location.search}`
    } else {
      urlLink = `dpl-screening/${searchValue}`
    }
    try {
      let res = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (res.success) {
        let dplData = []
        for (let i of res.data.results) {
          dplData.push({ ...i, key: i.id })
        }
        this.setState({
          dataSource: dplData,
          count: res.data.count,
          loader: false,
          searchText: searchValue || null,
          isFilter: false,
          filterValueArray: [],
        })
        message.success('Fetched Successfully')
        this.getExportData()
      } else {
        message.error(res.message)
        this.setState({
          dataSource: [],
          count: 0,
          loader: false,
          searchText: searchValue || null,
        })
      }
    } catch {
      this.setState({
        dataSource: [],
        count: 0,
        loader: false,
        searchText: searchValue || null,
      })
      message.error('Failed to Fetch')
    }
  }

  //function to fetch filtered data
  async getFilteredData(searchValue = '', currentPage = 1) {
    let urlLink
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.search
    ) {
      urlLink = `dpl-screening/${this.props.history.location.search}&&${searchValue}`
    } else {
      urlLink = `dpl-screening/?${searchValue}`
    }

    this.setState({ loader: true })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        let orders = []
        for (let o of userRes.data.results) {
          orders.push({ ...o, key: o.id })
        }

        this.setState({
          dataSource: orders,
          loader: false,
          count: userRes.data.count,
          searchText: searchValue || null,
          currentPage,
          filterApplied: searchValue,
        })
        message.success('Filter Applied')
        this.getExportDataFiltered(searchValue)
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      message.error('Filter Not Applied')
    }
  }

  //search fumnction
  onSearch = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      let stringSplit = value.split('=')
      if (i.includes(stringSplit[0])) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    arrayOfFilter.push(value)
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      filterString: `${arrayOfFilter.join('&&')}`,
      highlightFilterArray: highlightArray,
    })
    this.getFilteredData(`${arrayOfFilter.join('&&')}`)
  }

  //function to reset applied filters
  onFilterReset = (value = '') => {
    let arrayOfFilter = this.state.filterValueArray
    arrayOfFilter.filter((i, index) => {
      if (i.includes(value)) {
        arrayOfFilter.splice(index, 1)
        return i
      }
    })
    let highlightArray = []
    let isFilterColor = arrayOfFilter.map((i) => {
      highlightArray.push(i.split('=')[0])
    })
    this.setState({
      filterValueArray: arrayOfFilter,
      isFilter: true,
      highlightFilterArray: highlightArray,
    })
    if (arrayOfFilter.length == 0) {
      this.fetchData('')
    } else {
      this.setState({ filterString: `${arrayOfFilter.join('&&')}` })
      this.getFilteredData(`${arrayOfFilter.join('&&')}`)
    }
  }

  getExportData = async (searchValue = '') => {
    this.setState({ highlightFilterArray: [] })
    let urlLink
    if (
      this.props &&
      this.props.history.location &&
      this.props.history.location.search &&
      this.state.searchText != null
    ) {
      urlLink = `dpl-screening/${this.props.history.location.search}&limit=200`
    } else {
      urlLink = `dpl-screening/${searchValue}?limit=200`
    }
    try {
      let res = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (res.success) {
        let dplData = []
        for (let i of res.data.results) {
          dplData.push({ ...i, key: i.id })
        }
        this.setState({
          exportAllData: dplData,
          loader: false,
        })
      } else {
        this.setState({
          loader: false,
        })
      }
    } catch {
      this.setState({
        loader: false,
      })
    }
  }

  async getExportDataFiltered(searchValue) {
    let urlLink
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.search
    ) {
      urlLink = `dpl-screening/${this.props.history.location.search}&&${searchValue}?limit=200`
    } else {
      urlLink = `dpl-screening/?${searchValue}&limit=200`
    }

    // this.setState({ loader: true })
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        let orders = []
        for (let o of userRes.data.results) {
          orders.push({ ...o, key: o.id })
        }

        this.setState({
          exportAllData: orders,
          loader: false,
        })
        // message.success('Filter Applied')
        // this.getExportDataFiltered()
      } else {
        this.setState({ loader: false })
        // message.error(userRes.message)
      }
    } catch (error) {
      message.error('Filter Not Applied')
    }
  }

  // handleExportAllData = (columns) => {
  //   const data = this.state.exportAllData
  //   let fileContent = []
  //   for (let order of data) {
  //     let csvObj = {}
  //     for (let column of columns) {
  //       if (column.dataIndex !== 'action') {
  //         csvObj[column.dataIndex] = order[column.dataIndex]
  //       }
  //     }
  //     fileContent.push(csvObj)
  //   }
  //   let headersToShow = columns
  //     .map((column) => column.dataIndex !== 'action' && column.title)
  //     .filter((value) => value && value)
  //   downloadExcel('RoreOrder_import', fileContent, headersToShow)
  // }

  async getFilteredDPLData() {
    let urlLink = `export/dpl-xls/?${this.state.searchText}`
    let responseURL
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        responseURL = userRes.data.url
        this.setState({ exportURL: responseURL })
        window.location.href = responseURL
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      
    }
  }

  async handleExportAllData() {
    let urlLink = `export/dpl-xls/`
    let responseURL
    try {
      let userRes = await HTTPService({
        method: 'GET',
        url: urlLink,
      })
      if (userRes.success) {
        responseURL = userRes.data.url
        this.setState({ exportURL: responseURL })
        window.location.href = responseURL
      } else {
        this.setState({ loader: false })
        message.error(userRes.message)
      }
    } catch (error) {
      this.setState({ loader: false })
      
    }
  }

  //function for search functionaliity in table columns
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
      <div className="search_filterDropdown">
        <Input
          className="search_input"
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            clearFilters()
            this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
          }}
        />
        <Space className="btns">
          <Button
            type="primary"
            onClick={() => {
              clearFilters()
              this.onSearch(`${dataIndex}=${selectedKeys[0].trim()}`)
            }}
            icon={<SearchOutlined />}
            size="medium"
          >
            Search
          </Button>
          <Button
            onClick={() => {
              // clearFilters()
              this.onFilterReset(`${dataIndex}`)
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#3653AA' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#fafafa', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  handleChange = (value, keyName, record, isDate = false) => {
    if (isDate) {
      record[keyName] = moment().format(DATE_FORMAT)
      return
    }
    record[keyName] = value
  }

  //function to toggle flag to open Add/Edit form
  setFormStatus = (dplLOgData) => {
    this.setState({ isAddPart: true })

    this.setState({
      isFormOpen: !this.state.isFormOpen,
      currentData: dplLOgData ? dplLOgData : null,
      searchText: `?web_order=${dplLOgData.web_order}`,
    })
  }

  handleSave = (row) => {
    const newData = [...this.state.dataSource]
    const index = newData.findIndex((item) => row.id === item.id)
    const item = newData[index]
    newData.splice(index, 1, { ...item, ...row })
    this.setState({
      dataSource: newData,
    })
  }

  //function to fetch data fot table on page change
  onChangePageNumber = async (pageNumber, PAGE_LIMIT) => {
    this.setState({ loader: true, pageLimit: PAGE_LIMIT })
    let offset = (pageNumber - 1) * PAGE_LIMIT
    try {
      let catelogRes
      if (this.state.searchText == null) {
        catelogRes = await HTTPService({
          method: 'GET',
          url: `dpl-screening/?${CATALOG_SORTING}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      } else {
        catelogRes = await HTTPService({
          method: 'GET',
          url: `dpl-screening/${this.state.searchText}&${CATALOG_SORTING}&limit=${PAGE_LIMIT}&offset=${offset}`,
        })
      }

      if (catelogRes.success) {
        let catelogs = [],
          count = 0
        for (let catelog of catelogRes.data.results) {
          catelogs.push({ ...catelog, key: catelog.id })
        }

        count = catelogRes.data.count
        this.setState({
          dataSource: catelogs,
          count,
          currentPage: pageNumber,
          currentData: null,
          isFormOpen: false,
          loader: false,
        })
      } else {
        message.error(catelogRes.message)
        this.setState({
          isFormOpen: false,
          loader: false,
        })
      }
    } catch {
      message.error('Failed to fetch')
      this.setState({
        isFormOpen: false,
        loader: false,
      })
    }
  }

  //function to display total item
  showTotal = () => {
    return `Total ${this.state.count}`
  }

  goBack() {
    this.props.history.goBack()
  }
  render() {
    this.columns = [
      {
        title: 'Web Order',
        dataIndex: 'web_order',
        width: 200,
        ...this.getColumnSearchProps('web_order'),
        className: this.state.highlightFilterArray.includes('web_order')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company Name',
        dataIndex: 'company_name',
        width: 200,
        ...this.getColumnSearchProps('company_name'),
        className: this.state.highlightFilterArray.includes('company_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Company Country',
        dataIndex: 'company_country',
        ...this.getColumnSearchProps('company_country'),
        width: 200,
        className: this.state.highlightFilterArray.includes('company_country')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Requestor Name',
        dataIndex: 'requestor_name',
        width: 200,
        ...this.getColumnSearchProps('requestor_name'),
        className: this.state.highlightFilterArray.includes('requestor_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Contact Name',
        dataIndex: 'contact_name',
        width: 200,
        ...this.getColumnSearchProps('contact_name'),
        className: this.state.highlightFilterArray.includes('contact_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Shipto Name',
        dataIndex: 'shipto_name',
        width: 200,
        ...this.getColumnSearchProps('shipto_name'),
        className: this.state.highlightFilterArray.includes('shipto_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Shipto Country',
        dataIndex: 'shipto_country',
        width: 200,
        ...this.getColumnSearchProps('shipto_country'),
        className: this.state.highlightFilterArray.includes('shipto_country')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL Audit',
        dataIndex: 'dpl_audit',
        width: 200,
        ...this.getColumnSearchProps('dpl_audit'),
        className: this.state.highlightFilterArray.includes('dpl_audit')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL Name',
        dataIndex: 'dpl_name',
        width: 200,
        ...this.getColumnSearchProps('dpl_name'),
        className: this.state.highlightFilterArray.includes('dpl_name')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL Street',
        dataIndex: 'dpl_street',
        width: 200,
        ...this.getColumnSearchProps('dpl_street'),
        className: this.state.highlightFilterArray.includes('web_order')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL City',
        dataIndex: 'dpl_city',
        width: 200,
        ...this.getColumnSearchProps('dpl_city'),
        className: this.state.highlightFilterArray.includes('dpl_city')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL State',
        dataIndex: 'dpl_state',
        width: 200,
        ...this.getColumnSearchProps('dpl_state'),
        className: this.state.highlightFilterArray.includes('dpl_state')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL Country',
        dataIndex: 'dpl_country',
        width: 200,
        ...this.getColumnSearchProps('dpl_country'),
        className: this.state.highlightFilterArray.includes('dpl_country')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'DPL Request',
        dataIndex: 'dpl_request',
        width: 200,
        // ...this.getColumnSearchProps('dpl_request'),
        ellipsis: {
          showTitle: true,
        },
        className: this.state.highlightFilterArray.includes('dpl_request')
          ? 'filtered_text ellipsis_text'
          : 'ellipsis_text',
        // className: ,
      },
      {
        title: 'DPL Response',
        dataIndex: 'dpl_response',
        width: 200,
        // ...this.getColumnSearchProps('dpl_response'),
        ellipsis: {
          showTitle: true,
        },
        className: this.state.highlightFilterArray.includes('dpl_response')
          ? 'filtered_text ellipsis_text'
          : 'ellipsis_text',
      },

      {
        title: 'Release',
        dataIndex: 'release',
        width: 200,
        ...this.getColumnSearchProps('release'),
        className: this.state.highlightFilterArray.includes('release')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Processed',
        dataIndex: 'processed',
        width: 200,
        ...this.getColumnSearchProps('processed'),
        className: this.state.highlightFilterArray.includes('processed')
          ? 'filtered_text'
          : '',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        fixed: 'right',
        width: 80,
        align: 'center',
        render: (text, record) =>
          this.state.dataSource.length >= 1 ? (
            <Fragment>
              <Link className="ico_edit">
                <EditOutlined onClick={() => this.setFormStatus(record)} />
              </Link>
            </Fragment>
          ) : null,
      },
    ]
    //function to select records/rows of table
    const { dataSource, currentPage, count } = this.state
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }
    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      }
    })

    const renderTable = () => {
      const rowSelection = {
        selectedRowKeys: this.state.selectedLog,
        onChange: (selectedLogs, selectedRows) => {
          this.setState({ selectedLog: selectedLogs })
        },
      }

      return (
        <div className="DPLsreeningTableStyles">
          <div className="DPLscreeningStyleEdit_container">
            <div className="Table_top_row">
              <div className="Table_heading">DPL SCreening Log</div>
              <div className="Table_action_btns">
                <div className="table_search_block">
                  <Button
                    type="primary"
                    size="large"
                    // onClick={() =>
                    //   this.props.history.push({
                    //     pathname: '/sampleorder',
                    //   })
                    // }
                    onClick={this.goBack}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => this.fetchData('')}
                  >
                    Reset
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      let selectedLog = this.state.selectedLog
                      let data = []
                      this.state.dataSource.filter((i) => {
                        let mpnData = i.id
                        let index = selectedLog.indexOf(mpnData)
                        if (index != -1) {
                          data.push(this.state.dataSource[index])
                        }
                      })
                      if (this.state.selectedLog.length > 0) {
                        let fileContent = []
                        for (let order of data) {
                          let csvObj = {}
                          for (let column of columns) {
                            if (column.dataIndex !== 'action') {
                              csvObj[column.dataIndex] = order[column.dataIndex]
                            }
                          }
                          fileContent.push(csvObj)
                        }
                        let headersToShow = columns
                          .map(
                            (column) =>
                              column.dataIndex !== 'action' && column.title
                          )
                          .filter((value) => value && value)
                        downloadExcel(
                          'RoreOrder_import',
                          fileContent,
                          headersToShow
                        )
                      } else message.info('Select orders to export')
                    }}
                  >
                    Export
                  </Button>

                  <Button
                    size="large"
                    onClick={() => this.handleExportAllData()}
                  >
                    Export All
                  </Button>
                </div>
              </div>
            </div>
            <div className="layout_content_box CatalogTableEdit_whitebox">
              <Table //render dplLOgData table
                className="Table_block_main"
                components={components}
                scroll={{ x: 1500, y: 500 }}
                rowClassName={() => 'editable-row'}
                bordered
                rowSelection={{
                  ...rowSelection,
                }}
                dataSource={dataSource || []}
                columns={columns}
                pagination={{
                  onChange: this.onChangePageNumber,
                  current: currentPage,
                  total: count,
                  defaultPageSize: this.state.pageLimit,
                  showSizeChanger: true,
                  onShowSizeChange: this.onChangePageNumber,
                  showTotal: this.showTotal,
                }}
                loading={this.state.loader}
                rowKey="id"
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        {/* {renderTable()} */}
        {this.state.isFormOpen ? (
          this.state.loader ? (
            <div className="loader">
              <Spin size="large" />{' '}
            </div>
          ) : (
            //Add/Edit Form
            <DPLscreeningForm
              dplLOgData={this.state.currentData}
              currentPage={this.state.currentPage}
              onChangePageNumber={this.onChangePageNumber}
              setFormStatus={this.setFormStatus}
              isAddPart={this.state.isAddPart}
              pageLimit={this.state.pageLimit}
            />
          )
        ) : (
          renderTable()
        )}
      </div>
    )
  }
}

export default withRouter(DPLscreeningTable)
